import { Icons, Button, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { StyledFooterBar } from "./styles"
import { Trans } from "react-i18next"
import i18n from "src/i18n"

export const FooterBar = ({
  isLoading,
  currentPage,
  totalPages,
  nextHandleClick,
  nextLabel = i18n.t("components.footer.nextLabel"),
}: {
  isLoading: boolean
  currentPage: number
  totalPages: number
  nextHandleClick: any
  nextLabel?: string
}) => {
  const theme = useTheme()

  return (
    <StyledFooterBar>
      <Typography variant="headline8" color="neutral.30">
        <Trans
          i18nKey={"page.signing.footer.currentPage"}
          components={{
            highlight: <span style={{ color: theme.colors.primary }} />,
          }}
          values={{ currentPage, totalPages }}
        />
      </Typography>
      <Button
        variant="primary"
        size="medium"
        disabled={isLoading}
        onClick={() => nextHandleClick && nextHandleClick()}
      >
        <Typography variant="body3" weight={700}>
          {nextLabel}
        </Typography>
        <Icons
          name="IconArrowRight"
          fill="transparent"
          color={theme.colors.neutral[100]}
        />
      </Button>
    </StyledFooterBar>
  )
}

export default FooterBar
