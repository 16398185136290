import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  background: ${({ theme }) => theme.colors.neutral[90]};
`

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutral[95]};
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.xs1}`};

  @media screen and (max-width: 968px) {
    grid-template-columns: 1fr 1fr;
    border-top: ${({ theme }) => theme.borders.width.s} solid
      ${({ theme }) => theme.colors.neutral[90]};
  }
`

export const CanvasWrappper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media screen and (max-width: 968px) {
    > canvas {
      width: 100%;
    }
  }
`
