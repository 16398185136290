import { useContext, useState } from "react"
import { Content, Start } from "./screens"
import { ResignationContext } from "src/context"

enum StepEnum {
  initial,
  content,
}

export const ResignationLetter = () => {
  const [step, setStep] = useState<StepEnum>(StepEnum.initial)

  const { cardId } = useContext(ResignationContext)

  switch (step) {
    case StepEnum.initial:
      return <Start handleNextStep={() => setStep(StepEnum.content)} />
    case StepEnum.content:
      return <Content cardId={cardId} />
  }
}
