import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import {
  Icons,
  TextField,
  SelectField,
  Typography,
  dayjs,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2"

import { useTranslation } from "react-i18next"

import { InputDate } from "@Components"
import { Context } from "../../../../context"

import ArrowRight from "../../../../assets/arrow_right.svg"

import {
  Container,
  RightContainer,
  LeftContainer,
  StepTitle,
  SubTitle,
  ButtonContainer,
  StyledCheckbox,
  CheckBoxArea,
  HelperText,
} from "./styles"

import { useFormik } from "formik"
import * as yup from "yup"
import { Candidate, HiringCard } from "server/src/types"
import { trpc } from "src/api/trpc"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { CpfMask, validateCPF } from "@Utils/index"
import { PhoneMask, validatePhone } from "@Utils/validation"
import dispatchToast from "@Utils/dispatchToast"
import { maritalStatusOptions } from "@Utils/mocks"

const BasicDataStep = ({
  candidate,
  card,
}: {
  candidate: Candidate
  card: HiringCard
}) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.basicDataStep",
  })

  const navigate = useNavigate()
  const { subcategory } = useContext(Context)

  const { mutateAsync: updateCandidate } =
    trpc.candidate.updateCandidate.useMutation()

  const { mutateAsync: sendCardToNextColumn } =
    trpc.wizard.submitCandidateDocuments.useMutation()

  const setFieldValue = async (
    values: Parameters<typeof updateCandidate>["0"]["fields"]
  ) => {
    await updateCandidate({
      candidateId: candidate._id,
      fields: values,
    })
  }

  const validationSchema = yup.object({
    name: yup.string().required(t("form.fields.name.required")),
    email: yup
      .string()
      .email(t("form.fields.email.notValid"))
      .required(t("form.fields.email.required")),
    phone: yup.string().required(t("form.fields.phone.required")),
    documentNumber: yup
      .string()
      .required(t("form.fields.documentNumber.required")),
    gender: yup.string().required(t("form.fields.gender.required")),
    genderOther: yup
      .string()
      .when("gender", {
        is: "outro",
        then: yup.string().required(t("form.fields.gender.required")),
      })
      .nullable(),
    socialName: yup.string().nullable().notRequired(),
    birthDate: yup.string().required(t("form.fields.birthDate.required")),
    nationality: yup.string().required(t("form.fields.nationality.required")),
    race: yup.string().required(t("form.fields.race.required")),
    maritalStatus: yup
      .string()
      .required(t("form.fields.maritalStatus.required")),
    schooling: yup.string().required(t("form.fields.schooling.required")),
    isPCD: yup.boolean().nullable().notRequired(),
    termAccepted: yup
      .boolean()
      .equals([true], t("form.fields.terms.notAccepted"))
      .required(t("form.fields.terms.notAccepted")),
    cnpj: yup.string().when("subcategory", {
      is: "pj",
      then: yup.string().required(t("form.fields.cnpj.required")),
      otherwise: yup.string().nullable(),
    }),
    legalName: yup.string().when("subcategory", {
      is: "pj",
      then: yup.string().required(t("form.fields.legalName.required")),
      otherwise: yup.string().nullable(),
    }),
  })

  const formik = useFormik({
    initialValues: {
      name: candidate.name || "",
      email: candidate.email || "",
      phone: PhoneMask(candidate.phone ?? "") || "",
      documentNumber: candidate.documentNumber || "",
      gender: candidate.gender || "",
      genderOther: candidate.gender || "",
      socialName: candidate.socialName || "",
      birthDate: candidate.birthDate || "",
      nationality: candidate.nationality || "",
      race: candidate.race || "",
      maritalStatus: candidate.maritalStatus || "",
      schooling: candidate.schooling || "",
      isPCD: candidate.isPCD,
      termAccepted: candidate.termAccepted || false,
      cnpj: candidate.cnpj || "",
      legalName: candidate.legalName || "",
      subcategory,
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {}

      if (
        values.documentNumber.length > 0 &&
        !validateCPF(values.documentNumber)
      )
        errors.documentNumber = t("form.fields.documentNumber.notValid")

      if (values.phone.length > 1 && !validatePhone(values.phone))
        errors.phone = t("form.fields.phone.notValid")

      return errors
    },
    onSubmit: async () => {
      const track = trackList?.[subcategory]?.employee?.sendDocBasicDataContinue
      if (track) createSegmentTrack({ track })

      await sendCardToNextColumn({ flowCardId: card._id })

      navigate(`../wizard/hiring/candidate`, {
        replace: true,
      })
    },
    validateOnMount: true,
  })

  return (
    <Container>
      <LeftContainer>
        <StepTitle variant={"headline7"}>{t("title")}</StepTitle>
        <SubTitle variant={"body3"}>{t("subtitle")}</SubTitle>
      </LeftContainer>
      <RightContainer>
        <div
          style={{
            border: "1px solid #EBE5E9",
            borderRadius: "8px",
            padding: "40px 44px",
          }}
        >
          <Typography
            variant="headline8"
            style={{ fontWeight: "700", color: "#55444F", marginBottom: "4px" }}
          >
            {t("form.title")}
          </Typography>
          <div style={{ marginTop: "22px" }}>
            <TextField
              id="name"
              name={"name"}
              label={t("form.fields.name.label")}
              placeholder={t("form.fields.name.placeholder")}
              value={formik.values.name}
              onBlur={async (e) => {
                await setFieldValue({ name: e.target.value || "" })
              }}
              onChange={formik.handleChange}
              fullWidth={true}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </div>
          <div style={{ marginTop: "22px" }}>
            <TextField
              id="email"
              name={"email"}
              label={t("form.fields.email.label")}
              placeholder={t("form.fields.email.placeholder")}
              value={formik.values.email}
              onBlur={async (e) => {
                await setFieldValue({ email: e.target.value || "" })
              }}
              fullWidth={true}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>

          <div style={{ marginTop: "22px" }}>
            <TextField
              id="phone"
              name={"phone"}
              label={t("form.fields.phone.label")}
              placeholder={t("form.fields.phone.placeholder")}
              value={formik.values.phone}
              onBlur={async (e) => {
                await setFieldValue({
                  phone: e.target.value || "",
                })
              }}
              onChange={(e) => {
                if (e.target.value.length > 15) return

                const value = PhoneMask(e.target.value)
                formik.handleChange({
                  target: { name: "phone", value },
                })
              }}
              fullWidth={true}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </div>

          <div style={{ marginTop: "22px" }}>
            <TextField
              id="documentNumber"
              name={"documentNumber"}
              label={t("form.fields.documentNumber.label")}
              placeholder={t("form.fields.documentNumber.placeholder")}
              value={CpfMask(formik.values.documentNumber)}
              onChange={(e) => {
                if (e.target.value.length === 15) return
                formik.handleChange(e)
              }}
              onBlur={async (e) => {
                if (e.target.value.length < 14) return
                if (!validateCPF(e.target.value)) return
                await setFieldValue({
                  documentNumber: e.target.value.replace(/\D/g, "") || "",
                })
              }}
              fullWidth={true}
              error={
                formik.touched.documentNumber &&
                Boolean(formik.errors.documentNumber)
              }
              helperText={
                formik.touched.documentNumber && formik.errors.documentNumber
              }
            />
          </div>

          <div style={{ marginTop: "32px" }}>
            <SelectField
              id="gender"
              name={"gender"}
              label={t("form.fields.gender.label")}
              placeholder={t("form.fields.gender.placeholder")}
              required={true}
              value={formik.values.gender}
              onSelectChange={async (_, { value }) => {
                formik.handleChange({
                  target: {
                    id: "gender",
                    value: value,
                  },
                })

                await setFieldValue({ gender: value || "" })
              }}
              options={[
                { label: "Masculino", value: "male" },
                { label: "Feminino", value: "female" },
                { label: "Outro", value: "other" },
                {
                  label: "Prefiro não declarar",
                  value: "prefer not to disclose",
                },
              ]}
              fullWidth={true}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              helperText={formik.touched.gender && formik.errors.gender}
            />
          </div>

          <div style={{ marginTop: "32px" }}>
            <TextField
              id="socialName"
              name={"socialName"}
              label={t("form.fields.socialName.label")}
              placeholder={t("form.fields.socialName.placeholder")}
              value={formik.values.socialName}
              onBlur={async (e) => {
                await setFieldValue({
                  socialName: e.target.value || "",
                })
              }}
              onChange={formik.handleChange}
              fullWidth={true}
              error={
                formik.touched.socialName && Boolean(formik.errors.socialName)
              }
              helperText={formik.touched.socialName && formik.errors.socialName}
            />
          </div>

          {subcategory === "pj" && (
            <>
              <div style={{ marginTop: "32px" }}>
                <TextField
                  id="cnpj"
                  name="cnpj"
                  label={t("form.fields.cnpj.label")}
                  placeholder={t("form.fields.cnpj.placeholder")}
                  value={formik.values.cnpj}
                  onBlur={async (e) => {
                    await setFieldValue({
                      cnpj: e.target.value || "",
                    })
                  }}
                  onChange={formik.handleChange}
                  fullWidth={true}
                  error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                  helperText={formik.touched.cnpj && formik.errors.cnpj}
                />
              </div>

              <div style={{ marginTop: "32px" }}>
                <TextField
                  id="legalName"
                  name="legalName"
                  label={t("form.fields.legalName.label")}
                  placeholder={t("form.fields.legalName.placeholder")}
                  value={formik.values.legalName}
                  onBlur={async (e) => {
                    await setFieldValue({
                      legalName: e.target.value || "",
                    })
                  }}
                  onChange={formik.handleChange}
                  fullWidth={true}
                  error={
                    formik.touched.legalName && Boolean(formik.errors.legalName)
                  }
                  helperText={
                    formik.touched.legalName && formik.errors.legalName
                  }
                />
              </div>
            </>
          )}

          <div style={{ marginTop: "32px" }}>
            <InputDate
              id="birthDate"
              name={"birthDate"}
              label={t("form.fields.birthDate.label")}
              placeholder={t("form.fields.birthDate.placeholder")}
              value={formik.values.birthDate}
              toDate={dayjs()}
              onChange={async (value) => {
                if (!value || !dayjs(value).isValid()) return
                formik.handleChange({
                  target: {
                    id: "birthDate",
                    value: value,
                  },
                })

                await setFieldValue({ birthDate: value || "" })
              }}
              fullWidth={true}
              error={
                formik.touched.birthDate && Boolean(formik.errors.birthDate)
              }
              helperText={formik.touched.birthDate && formik.errors.birthDate}
            />
          </div>

          <div style={{ marginTop: "32px" }}>
            <SelectField
              id="nationality"
              name={"nationality"}
              label={t("form.fields.nationality.label")}
              placeholder={t("form.fields.nationality.placeholder")}
              required={true}
              value={formik.values.nationality}
              onSelectChange={async (_, { value }) => {
                formik.handleChange({
                  target: {
                    id: "nationality",
                    value: value,
                  },
                })

                await setFieldValue({ nationality: value || "" })
              }}
              options={[
                { label: "Brasileiro", value: "brazilian" },
                { label: "Estrangeiro", value: "foreigner" },
              ]}
              fullWidth={true}
              error={
                formik.touched.nationality && Boolean(formik.errors.nationality)
              }
              helperText={
                formik.touched.nationality && formik.errors.nationality
              }
            />
          </div>

          <div style={{ marginTop: "32px" }}>
            <SelectField
              id="race"
              name={"race"}
              label={t("form.fields.race.label")}
              placeholder={t("form.fields.race.placeholder")}
              required={true}
              value={formik.values.race}
              onSelectChange={async (_, { value }) => {
                formik.handleChange({
                  target: {
                    id: "race",
                    value: value,
                  },
                })

                await setFieldValue({ race: value || "" })
              }}
              options={[
                {
                  label: "Branco",
                  value: "white",
                },
                {
                  label: "Pardo",
                  value: "brown",
                },
                {
                  label: "Amarelo",
                  value: "yellow",
                },
                {
                  label: "Preto",
                  value: "black",
                },
                {
                  label: "Indígena",
                  value: "indigenous",
                },
                {
                  label: "Prefiro não declarar",
                  value: "prefer not to disclose",
                },
              ]}
              fullWidth={true}
              error={formik.touched.race && Boolean(formik.errors.race)}
              helperText={formik.touched.race && formik.errors.race}
            />
          </div>

          <div style={{ marginTop: "32px" }}>
            <SelectField
              id="maritalStatus"
              name={"maritalStatus"}
              label={t("form.fields.maritalStatus.label")}
              placeholder={t("form.fields.maritalStatus.placeholder")}
              required={true}
              value={formik.values.maritalStatus}
              onSelectChange={async (_, { value }) => {
                formik.handleChange({
                  target: {
                    id: "maritalStatus",
                    value: value,
                  },
                })

                await setFieldValue({ maritalStatus: value || "" })
              }}
              options={maritalStatusOptions}
              fullWidth={true}
              error={
                formik.touched.maritalStatus &&
                Boolean(formik.errors.maritalStatus)
              }
              helperText={
                formik.touched.maritalStatus && formik.errors.maritalStatus
              }
            />
          </div>

          <div style={{ marginTop: "32px" }}>
            <SelectField
              id="schooling"
              name={"schooling"}
              label={t("form.fields.schooling.label")}
              placeholder={t("form.fields.schooling.placeholder")}
              required={true}
              value={formik.values.schooling}
              onSelectChange={async (_, { value }) => {
                formik.handleChange({
                  target: {
                    id: "schooling",
                    value: value,
                  },
                })

                await setFieldValue({
                  schooling: value || "",
                })
              }}
              options={[
                {
                  label: "Ensino fundamental incompleto",
                  value: "incomplete primary education",
                },
                {
                  label: "Ensino fundamental completo",
                  value: "complete primary education",
                },
                {
                  label: "Ensino médio incompleto",
                  value: "incomplete secondary education",
                },
                {
                  label: "Ensino médio completo",
                  value: "complete secondary education",
                },
                {
                  label: "Ensino superior incompleto",
                  value: "incomplete higher education",
                },
                {
                  label: "Ensino superior completo",
                  value: "complete higher education",
                },
                {
                  label: "Pós graduação/especialização",
                  value: "postgraduate/specialization",
                },
                {
                  label: "Doutorado completo",
                  value: "complete doctorate",
                },
                {
                  label: "Mestrado",
                  value: "master's degree",
                },
                {
                  label: "Pós doutorado",
                  value: "postdoctoral degree",
                },
              ]}
              fullWidth={true}
              error={
                formik.touched.schooling && Boolean(formik.errors.schooling)
              }
              helperText={formik.touched.schooling && formik.errors.schooling}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "32px",
            }}
          >
            <StyledCheckbox
              id="isPCD"
              name={"isPCD"}
              checked={formik.values.isPCD}
              onChange={async (e) => {
                formik.handleChange({
                  target: {
                    id: "isPCD",
                    value: e.target.checked,
                  },
                })

                await setFieldValue({ isPCD: e.target.checked })
              }}
            />
            <Typography variant="body3">
              {t("form.fields.pcd.label")}
            </Typography>
          </div>

          <div>
            <CheckBoxArea>
              <StyledCheckbox
                id="termAccepted"
                name={"termAccepted"}
                checked={formik?.values?.termAccepted}
                onChange={async (e) => {
                  formik.handleChange({
                    target: {
                      id: "termAccepted",
                      value: e.target.checked,
                    },
                  })
                  await setFieldValue({ termAccepted: e.target.checked })
                }}
              />
              <Typography variant="body3">
                Li e aceito os{" "}
                <u
                  role="presentation"
                  onClick={() =>
                    window.open(
                      "https://www.flashapp.com.br/docs/flash-lgpd.pdf",
                      "_blank"
                    )
                  }
                >
                  Termos de Uso{" "}
                </u>
                e{" "}
                <u
                  role="presentation"
                  onClick={() =>
                    window.open(
                      "https://www.flashapp.com.br/docs/politica-de-privacidade.pdf",
                      "_blank"
                    )
                  }
                >
                  Políticas de Privacidade
                </u>{" "}
                da Flash
              </Typography>
            </CheckBoxArea>
            {formik.touched.termAccepted && formik.errors.termAccepted && (
              <HelperText variant="caption">
                <Icons name="IconInfoCircle" size={18} />
                {formik.errors.termAccepted}
              </HelperText>
            )}
          </div>
        </div>
        <ButtonContainer style={{ paddingTop: "60px", paddingBottom: "20px" }}>
          <Button
            variant="primary"
            size="large"
            type={"submit"}
            onClick={() => {
              formik.handleSubmit()
              if (!formik.isValid) {
                dispatchToast({
                  content: "Verifique os campos do formulário",
                  type: "error",
                })
              }
            }}
            style={{
              width: 311,
            }}
          >
            {t("buttons.continue")}
            <ArrowRight style={{ color: "#fff", marginLeft: "7px" }} />
          </Button>
        </ButtonContainer>
      </RightContainer>
    </Container>
  )
}

export default BasicDataStep
