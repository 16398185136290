import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const MainContainer = styled.div`
  background: ${({ theme }) => `${theme.colors.neutral[95]}`};
`

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: ${({ theme }) => `${theme.spacings.m}`};
  border: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => `${theme.spacings.s}`};

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    gap: 0;
    flex-direction: row;
    width: calc(100% - 20px);
    margin: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.xs3}`};
    padding: ${({ theme }) => `${theme.spacings.m}`};
    border-radius: ${({ theme }) => `${theme.borders.radius.s}`};
  }

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    width: calc(100% - 160px);
    margin: ${({ theme }) => `${theme.spacings.xl5}`};
    padding: ${({ theme }) => `${theme.spacings.xl5} ${theme.spacings.xl3}`};
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  gap: ${({ theme }) => `${theme.spacings.xs}`};

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 30%;
    margin-right: ${({ theme }) => `${theme.spacings.l}`};
  }

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    width: 45%;
    margin-right: ${({ theme }) => `${theme.spacings.xl5}`};
  }
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => `${theme.borders.radius.m}`};
  padding: ${({ theme }) => `${theme.spacings.m}`};
  gap: ${({ theme }) => `${theme.spacings.s}`};

  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
`

export const StyledButton = styled(Button)`
  width: 100%;
`
