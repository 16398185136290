import { Loader } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { useTheme } from "styled-components"
import { Box } from "../Box"

export const Loading = () => {
  const theme = useTheme()
  return (
    <Box
      $backgroundColor={theme.colors.neutral[95]}
      justifyContent="center"
      alignItems="center"
      $width={"100%"}
      $height={"100%"}
    >
      <Loader variant="primary" size="large" />
    </Box>
  )
}
