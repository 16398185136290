import {
  ActionWrapper,
  EmptyFileContainer,
  FieldContainer,
  FieldTitle,
  FileContainer,
  PreviewWrapper,
  ShareLinkButton,
  ShareText,
  ThumbnailPreview,
  Value,
} from "./styles"
import { IconButton, Icons, dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import { Component, FieldValue } from "server/src/types"
import {
  downloadArchive,
  firstCapitalLetter,
  removeDropzoneTimestamp,
} from "@Utils/index"
import dispatchToast from "@Utils/dispatchToast"
import { Option } from "@customTypes/index"

const renderFileField = (field: FieldValue) => {
  const { value = "", key = "" } = field.value || {}

  const lastIndexName = key ? key.lastIndexOf("/") : ""
  const nameOfArchive =
    key && lastIndexName
      ? removeDropzoneTimestamp(key.substring(lastIndexName + 1))
      : "Nome não identificado"

  return (
    <div>
      <FileContainer hasFile={!!value}>
        <PreviewWrapper>
          <ThumbnailPreview url={value} />
          <span>{nameOfArchive || field.placeholder}</span>
        </PreviewWrapper>

        <ActionWrapper>
          {!!value ? (
            <IconButton
              size="medium"
              variant="line"
              icon={"IconDownload"}
              onClick={async () => {
                if (!value) return
                await downloadArchive(value, nameOfArchive)
              }}
            />
          ) : (
            <EmptyFileContainer>
              <Icons name="IconX" fill="transparent" color="#C96C01" />
            </EmptyFileContainer>
          )}
        </ActionWrapper>
      </FileContainer>

      {!!value && (
        <ShareLinkButton
          variant="default"
          onClick={async () => {
            navigator.clipboard.writeText(value)
            dispatchToast({
              content: "Link copiado",
              type: "success",
            })
          }}
        >
          <>
            <ShareText variant="caption" style={{ fontWeight: 700 }}>
              Compartilhar link
            </ShareText>

            <Icons
              name="IconLink"
              size={16}
              style={{
                fill: "transparent",
                color: "#6B5B66",
              }}
            />
          </>
        </ShareLinkButton>
      )}
    </div>
  )
}

const renderOtherField = (field: FieldValue) => {
  const value = parseValue()

  function parseValue() {
    switch (field.component) {
      case Component.DatePicker:
        return field.value ? dayjs(field.value).format("DD/MM/YYYY") : null
      case Component.Checkbox:
        return field.value ? "Marcado" : "Não marcado"
      case Component.Select:
        const label = (field.options as Option[])?.find(
          (option) => option.value === field.value
        )?.label
        return label ? firstCapitalLetter(label) : field.value
      case Component.MultiSelect:
        return field.value
          ? field.value.map((value) => <span>{value.value}</span>)
          : null
      default:
        return field.value || null
    }
  }

  return (
    <FieldContainer>
      <FieldTitle>{field.placeholder}</FieldTitle>
      <Value hasValue={!!value}>{value || "Não preenchido"}</Value>
    </FieldContainer>
  )
}

export const PreviewField = ({ field }: { field: FieldValue }) => {
  const isFile = field.component === "attachment" || field.fieldType === "file"
  return isFile ? renderFileField(field) : renderOtherField(field)
}
