import { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "react-i18next"

import {
  Container,
  ProposalTitle,
  ProposalSubtitle,
  ExpireDate,
  Description,
  Avatar,
  LogoArea,
} from "./styles"

import { Button } from "@flash-tecnologia/hros-web-ui-v2"

import Logo from "../../../../assets/logo_flash.svg"
import { SignType } from "server/src/types"
import { Context } from "src/context"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { StartSigningContentProps } from "."

export const Content = ({ signature }: StartSigningContentProps) => {
  const navigate = useNavigate()
  const { expireDate } = useParams()
  const { subcategory, category } = useContext(Context)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const isResignation = category === "resignation"

  const validationTypeOthers =
    subcategory === "pj"
      ? "o contrato do(a) prestador(a) de serviço"
      : subcategory === "internship"
      ? "o termo de compromisso de estágio do candidato(a)"
      : isResignation
      ? "os documentos rescisórios do(a)"
      : "o contrato do(a) candidato(a)"

  const validationTypeCandidate =
    subcategory === "pj"
      ? "hora de assinar seu contrato de prestação de serviço"
      : subcategory === "internship"
      ? "hora de assinar seu termo de compromisso de estágio"
      : isResignation
      ? "confira e assine seus documentos rescisórios"
      : t("signing.start.title")

  return (
    <>
      {signature.type === SignType.company ? (
        <Container>
          <Avatar>{signature.name?.substring(0, 1)}</Avatar>
          <ProposalTitle variant={"headline6"}>
            <span>
              Hora de assinar {validationTypeOthers}{" "}
              <span style={{ textTransform: "capitalize" }}>
                {signature?.name?.split(" ")[0]}
              </span>
            </span>
          </ProposalTitle>
          <Description variant={"body3"}>
            {isResignation
              ? "Você recebeu documentos para análise e assinatura"
              : "Por favor assine o documento o mais rápido possível."}
          </Description>
          <Button
            onClick={() => {
              const track = trackList?.[subcategory]?.company?.analyzeDocument
              if (track)
                createSegmentTrack({
                  track: track,
                })

              navigate(`../wizard/signing/visualizeContract`, {
                replace: true,
              })
            }}
            variant="primary"
            size="large"
            style={{ alignSelf: "center" }}
          >
            {t("signing.start.buttonLabel")}
          </Button>
        </Container>
      ) : (
        <Container>
          <Avatar>{signature.name?.substring(0, 1).toUpperCase()}</Avatar>
          <ProposalTitle variant={"headline6"}>
            <span>{signature.name?.split(" ")[0]}</span>,{" "}
            {validationTypeCandidate}!
          </ProposalTitle>
          <ProposalSubtitle>
            {category === "resignation"
              ? "Você recebeu documentos para análise e assinatura"
              : t("signing.start.subtitle")}
          </ProposalSubtitle>
          {category === "resignation" ? (
            <></>
          ) : (
            <Description variant={"body3"}>
              {t("signing.start.description")}
            </Description>
          )}
          <Button
            onClick={() => {
              const track = trackList?.[subcategory]?.employee?.analyzeDocument
              if (track)
                createSegmentTrack({
                  track: track,
                })

              navigate(`../wizard/signing/visualizeContract`, {
                replace: true,
              })
            }}
            variant="primary"
            size="large"
            style={{ alignSelf: "center" }}
          >
            {t("signing.start.buttonLabel")}
          </Button>
        </Container>
      )}

      <LogoArea>
        {t("signing.start.developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </>
  )
}
