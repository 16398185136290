import styled from "styled-components"
import { motion } from "framer-motion"

export const Container = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.l}`};
  background: ${({ theme }) => theme.colors.neutral[100]};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.lg}) {
    padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs1}`};
  }
`

export const HeaderContent = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%;
  background-color: rgba(74, 78, 87, 0.1);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
`

export const ProgressBar = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100%;
`
