import {
  ProgressBar,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { Container, ProgressContainer } from "./styles"
import { Box } from "../../Box"
import { EllipsisTypography } from "../../EllipsisTypography"
import { useProgressAnimation } from "@Hooks"
import { useTranslation } from "react-i18next"

export enum UploadLoadingStates {
  Progress = "progress",
  Finished = "finished",
}

type LoadingProps = {
  fileName: string
  progress: number
  updateDuration?: number
}

export const Loading = ({
  fileName,
  progress,
  updateDuration = 2000,
}: LoadingProps) => {
  const theme = useTheme()
  const animatedProgress = useProgressAnimation(progress, updateDuration)

  const [t] = useTranslation("translations", { keyPrefix: "components.upload" })

  const state: UploadLoadingStates =
    animatedProgress !== 100
      ? UploadLoadingStates.Progress
      : UploadLoadingStates.Finished

  return (
    <Container state={state}>
      <ShapeIcon
        name="IconUpload"
        variant={state === UploadLoadingStates.Finished ? "success" : "default"}
        color={
          state === UploadLoadingStates.Finished
            ? theme.colors.feedback.success[40]
            : theme.colors.secondary[50]
        }
        size={64}
      />

      <Box
        flexDirection="column"
        gap="xs5"
        alignItems="center"
        $mt="xs3"
        $mb="xs"
      >
        <EllipsisTypography variant="body3" weight={700} color="neutral.30">
          {fileName}
        </EllipsisTypography>
        <Typography variant="body3" weight={400} color="neutral.30">
          {t(`loadingLabel.${state}`)}
        </Typography>
      </Box>

      <ProgressContainer>
        <ProgressBar
          hideLabel={false}
          variant={
            state === UploadLoadingStates.Finished ? "success" : "default"
          }
          progress={animatedProgress}
        />
      </ProgressContainer>
    </Container>
  )
}
