export enum SignedUrlCommandEnum {
  PUT = "put",
  GET = "get",
}

export type FileType = {
  path: string
}

export enum StorageGatewayModules {
  Hiring = "hiring",
  Resignation = "resignation",
}
