import { useTranslation } from "react-i18next"
import { Container, Avatar, LogoArea } from "./styles"
import Logo from "../../../../assets/logo_flash.svg"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { trackList, createSegmentTrack } from "@Utils/segment"
import { useContext } from "react"
import { SignType, StorageGatewayModules } from "server/src/types"
import { Context } from "src/context"
import { signingContext } from "../.."
import { Box, CenterTypography, Upload } from "@Components"
import { useTheme } from "styled-components"

const Download = () => {
  const { type: userType, subcategory } = useContext(Context)
  const { signData } = useContext(signingContext)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.signing.download",
  })
  const theme = useTheme()

  const trackUserList = {
    [SignType.Candidate]: trackList?.[subcategory]?.employee,
    [SignType.Company]: trackList?.[subcategory]?.company,
  }

  const track = trackUserList?.[userType]

  const { path } = signData.contractLastOutput

  return (
    <Box
      $height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Container>
        <Avatar>
          <Icons
            name="IconMoodHappy"
            width={64}
            height={64}
            fill="transparent"
            color={theme.colors.neutral[100]}
          />
        </Avatar>
        <Box flexDirection="column" gap="m" style={{ maxWidth: "400px" }}>
          <CenterTypography variant={"body3"} color="neutral.40">
            {t("title")}
          </CenterTypography>
          <Upload.Preview file={{ path }} module={StorageGatewayModules.Hiring}>
            <Upload.Action
              icon="IconDownload"
              onClick={({ url }) => {
                if (track)
                  createSegmentTrack({ track: track.signContractDownload })
                window.open(url, "_blank")
              }}
            />
          </Upload.Preview>
        </Box>
      </Container>
      <LogoArea>
        {t("developedBy")}
        <Logo />
      </LogoArea>
    </Box>
  )
}

export default Download
