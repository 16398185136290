import React from "react"
import { Container, EllipsisTypography, PreviewImage } from "./styles"
import { Skeleton } from "@mui/material"
import {
  FileType,
  SignedUrlCommandEnum,
  StorageGatewayModules,
} from "server/src/types"
import { trpc } from "src/api/trpc"
import { Box } from "../../Box"

type PreviewProps = {
  file: FileType
  children?: React.ReactNode
  module: StorageGatewayModules
}

export interface PreviewState {
  fileName?: string
  url?: string
}

export const previewContext = React.createContext<PreviewState>(
  {} as PreviewState
)

export const Preview = ({ file, module, children }: PreviewProps) => {
  const [url, setUrl] = React.useState<string | null>(null)

  const { mutateAsync: getPresignedUrlMutate } =
    trpc.helper.getPreSignedUrl.useMutation()

  const nameOfArchive = React.useMemo(() => {
    const lastIndexName = file.path.lastIndexOf("/") || ""
    if (lastIndexName) {
      const keyFileName = file.path.substring(lastIndexName + 1)
      const withoutTimestamp = keyFileName.split("-").slice(1).join("-")
      return withoutTimestamp
    }
  }, [file.path])

  React.useEffect(() => {
    getPresignedUrlMutate({
      filePath: file.path,
      command: SignedUrlCommandEnum.GET,
      module,
    }).then(({ url }) => {
      setUrl(url)
    })
  }, [file.path])

  const contextValue = React.useMemo(
    () => ({ url: url || undefined, fileName: nameOfArchive }),
    [url, nameOfArchive]
  )

  return (
    <Container>
      {url ? (
        <PreviewImage url={url} />
      ) : (
        <Skeleton variant="rectangular" width={45} height={45} />
      )}

      <EllipsisTypography variant="body3" weight={700} color="neutral.30">
        {nameOfArchive}
      </EllipsisTypography>

      <Box gap="xs3">
        <previewContext.Provider value={contextValue}>
          {children}
        </previewContext.Provider>
      </Box>
    </Container>
  )
}
