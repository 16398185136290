import { ILimitDate, SignType } from "server/src/types"
import { Content } from "./Content"
import { Context } from "src/context"
import { useContext } from "react"
import { signingContext } from "../.."
import { Box } from "@Components"
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2"

function getExpirationDate(
  limitDate?: ILimitDate<any>
): React.ComponentProps<typeof Content>["expireDate"] {
  if (!limitDate) return undefined

  const dayjsDate = dayjs(limitDate.date)
  return {
    formattedDate: dayjsDate.format("DD/MM/YYYY").toString(),
    formattedTime: dayjsDate.format("HH"),
  }
}

const StartSigningPage = () => {
  const { type } = useContext(Context)
  const { signData } = useContext(signingContext)

  const foundSignature = signData.contractSignatures.find((signInfo) =>
    [SignType.Candidate, SignType.Employee].includes(signInfo.type)
  )

  const expireDate = getExpirationDate(signData.cardLimitDate)

  if (!foundSignature) return <></>

  return (
    <Box
      $height={"100%"}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Content
        targetName={foundSignature?.name || ""}
        signerType={type as SignType}
        expireDate={expireDate}
      />
    </Box>
  )
}

export default StartSigningPage
