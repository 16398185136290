import styled from "styled-components"

export const StyledFooterBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral[100]};

  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
  z-index: 99;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
`
