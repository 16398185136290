import { useRef, useState, useEffect, useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { Box, StepperSigning } from "@Components"
import {
  Icons,
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  MenuCircle,
  ProgressBarContainer,
  ProgressBar,
  MenuContainer,
} from "./styles"
import { HeaderProps } from ".."
import { useTheme } from "styled-components"

export const Mobile = ({ companyName, companyLogo }: HeaderProps) => {
  const { route } = useParams()

  const ref = useRef<HTMLDivElement>(null)

  const [menuContainerHeight, setMenuContainerHeight] = useState(0)
  const [active, setActive] = useState(false)

  const theme = useTheme()

  const progress = useMemo(() => {
    switch (route) {
      case "visualizeContract":
        return "50.00%"
      case "signData":
        return "100%"
      default:
        return "0%"
    }
  }, [route])

  useEffect(() => {
    setMenuContainerHeight(ref?.current?.clientHeight || 0)
  }, [])

  const setMenuActive = useCallback(() => {
    setActive(!active)
  }, [active])

  return (
    <Box
      flexDirection="column"
      $backgroundColor={theme.colors.neutral[95]}
      ref={ref}
    >
      <ProgressBarContainer>
        <ProgressBar
          initial={{ opacity: 1, width: 0 }}
          animate={{ opacity: 1, width: progress }}
          transition={{ duration: 0.7 }}
        />
      </ProgressBarContainer>
      <Box
        justifyContent="space-between"
        alignItems="center"
        $py="xs2"
        $px="xs"
      >
        <Box alignItems="center" gap="xs3">
          <GenericProfilePicture
            name={companyName}
            source={companyLogo}
            size={40}
          />
          <Typography variant="body4" weight={700} color="neutral.20">
            {companyName}
          </Typography>
        </Box>
        <MenuCircle onClick={setMenuActive}>
          <Icons
            name={active ? "IconX" : "IconMenu"}
            fill={theme.colors.neutral[40]}
            size={18}
          />
        </MenuCircle>
      </Box>
      <MenuContainer menuHeight={menuContainerHeight} active={active}>
        <StepperSigning onItemClick={setMenuActive} />
      </MenuContainer>
    </Box>
  )
}
