import styled from "styled-components"

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: #fff;
  padding: 82px 112px;
  margin: 48px 32px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding: 40px 24px;
    margin: 0;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
      border: transparent;
    }
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin: 0;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
      border: transparent;
    }
  }
`

export const LeftContainer = styled.div`
  max-width: 515px;
  width: 100%;
  padding-right: 92px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-bottom: 32px;
    padding: 0;
  }
`

export const RightContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    min-width: 450px;
  }
`

export const ActionFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    flex-direction: column-reverse;
    gap: 24px;

    > :nth-child(2) {
      width: 100%;
    }
  }
`

export const RightSubContainer = styled.div`
  display: flex;
  padding: 40px 44px;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
`
