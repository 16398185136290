import { useContext } from "react"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate } from "react-router-dom"

import { useTranslation } from "react-i18next"

import {
  Container,
  ProposalTitle,
  ProposalSubtitle,
  Description,
  Avatar,
  LogoArea,
  VisualizeButton,
} from "./styles"

import Logo from "../../../../assets/logo_flash.svg"
import { Context } from "../../../../context"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { Candidate } from "server/src/types"

const StartStep = ({ candidate }: { candidate: Candidate }) => {
  const { subcategory } = useContext(Context)
  const navigate = useNavigate()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.uploadDocuments.start",
  })

  return (
    <>
      <Container>
        <Avatar>{candidate.name.substring(0, 1).toUpperCase()}</Avatar>
        <ProposalTitle>
          {t("title", { replace: { name: candidate.name.split(/\s/)[0] } })}
        </ProposalTitle>
        <ProposalSubtitle>{t("subtitle")}</ProposalSubtitle>
        <Description>{t("description")}</Description>
        <VisualizeButton
          onClick={() => {
            const track = trackList?.[subcategory]?.employee?.sendDocStart
            if (track) createSegmentTrack({ track: track })

            navigate(`../wizard/hiring/basic-data`, { replace: true })
          }}
        >
          <Typography variant="body3" weight={700}>
            {t("startButton")}
          </Typography>
        </VisualizeButton>
      </Container>

      <LogoArea>
        {t("developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </>
  )
}

export default StartStep
