import { useMemo } from "react"
import { useParams } from "react-router-dom"
import {
  GenericProfilePicture,
  Typography,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  Container,
  HeaderContent,
  ProgressBarContainer,
  ProgressBar,
} from "./styles"
import { Box, BreadCrumbsSigning } from "@Components"
import Logo from "../../../../../assets/logo_flash.svg"
import { HeaderProps } from ".."

export const Desktop = ({ companyName, companyLogo }: HeaderProps) => {
  const { route } = useParams()

  const isSmallScreen = useMediaQuery("(max-width: 1010px)")

  const progress = useMemo(() => {
    switch (route) {
      case "visualizeContract":
        return "50.00%"
      case "signData":
        return "100%"
      default:
        return "0%"
    }
  }, [route])

  return (
    <Container>
      <HeaderContent>
        <Box alignItems="center" gap="xs3">
          <GenericProfilePicture
            name={companyName}
            source={companyLogo}
            size={40}
          />
          {!isSmallScreen && (
            <Typography variant="body4" weight={700} color="neutral.20">
              {companyName}
            </Typography>
          )}
        </Box>
        <Box justifyContent="center">
          <BreadCrumbsSigning />
        </Box>
        <Logo />
        <ProgressBarContainer>
          <ProgressBar
            initial={{ opacity: 1, width: 0 }}
            animate={{ opacity: 1, width: progress }}
            transition={{ duration: 0.7 }}
          />
        </ProgressBarContainer>
      </HeaderContent>
    </Container>
  )
}
