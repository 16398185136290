import { MonitoringErrorBoundary, MonitoringManager } from "@flash-hros/utility"

// import { MonitoringLevel } from "@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel";
// project needs to be migrated to utility package

const projectDsn =
  "https://80ab688cd91f88aeaef64d2419e55fb3@o266934.ingest.us.sentry.io/4506905654853632"

export class ErrorBoundary extends MonitoringErrorBoundary {
  project = projectDsn

  constructor(props) {
    super(props)
  }

  static captureException(
    error: Error,
    // severity?: MonitoringLevel,
    // project needs to be migrated to utility package
    severity: any,
    extras?: Record<string, any>
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras)
  }

  static identifyUser(id: string) {
    MonitoringManager.identifyUser(id)
  }

  componentDidCatch(error: any) {
    MonitoringManager.captureException(this.project, error)
    window.location.replace("/wizard/invalid?type=invalid")
  }
}
