import React, { useEffect } from "react"
import { getFromLS, setInLS, parseJWT } from "@flash-hros/utility"
import { removeTokenFromURL, shallowEqual } from "@Utils/index"
import {
  FlowCategory,
  FlowSubcategory,
  HiringCard,
  ResignationCard,
} from "server/src/types"
import { trpc } from "src/api/trpc"
import { Outlet } from "react-router-dom"
import { Loading } from "@Components"

export interface TokenData {
  cardId: string
  employeeId: string
  candidateId: string
  companyId: string
  name: string
  type: string
  category: FlowCategory
  subcategory: FlowSubcategory
  token: string
}

interface IContext extends TokenData {}

const Context = React.createContext<IContext>(null!)

const ContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [hasSetup, setHasSetup] = React.useState<boolean>(false)
  const [tokenData, setTokenData] = React.useState<TokenData>({
    cardId: "",
    employeeId: "",
    candidateId: "",
    companyId: "",
    name: "",
    type: "",
    category: FlowCategory.hiring,
    subcategory: FlowSubcategory.clt,
    token: "",
  })

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tokenFromURL = urlParams.get("token")

    const token = tokenFromURL || getFromLS("wizard-token")
    if (tokenFromURL) {
      setInLS({ key: "wizard-token", value: token })
      removeTokenFromURL(urlParams)
    }

    setHasSetup(true)
    if (!token) return

    const decodedToken = parseJWT({ token })
    const parsedToken: TokenData = {
      cardId: decodedToken.cardId,
      category: decodedToken.category ?? getCategoryFromPage(),
      companyId: decodedToken.companyId,
      employeeId: decodedToken.employeeId || "",
      candidateId: decodedToken.candidateId || "",
      name: decodedToken.name || "",
      subcategory: decodedToken.subcategory || "",
      type: decodedToken.type,
      token: token,
    }

    if (shallowEqual(token, parsedToken)) return
    setTokenData(parsedToken)
  }, [])

  const shouldRequestData =
    !!tokenData.cardId &&
    (!!tokenData.category || !!getCategoryFromPage()) &&
    pageShouldRequest()

  const { data, isLoading, error } = trpc.hiring.getFlowCardData.useQuery(
    {
      cardId: tokenData.cardId,
      category: tokenData.category as FlowCategory,
    },
    { enabled: shouldRequestData }
  )

  function getCategoryFromPage(): FlowCategory | undefined {
    const path = window.location.pathname
    const page: string = path.split("/")[2]

    switch (page) {
      case "aso":
        return FlowCategory.hiring
      case "resignationAso":
        return FlowCategory.resignation
      default:
        return undefined
    }
  }

  function pageShouldRequest(): boolean {
    const path = window.location.pathname
    const page: string = path.split("/")[2]

    return !["invalid", "finished"].includes(page)
  }

  useEffect(() => {
    if (data && !data.allowed) {
      window.location.href = "/wizard/invalid?type=expired"
    }
    if (error) {
      window.location.href = "/wizard/invalid"
    }
  }, [data, error])

  if (!hasSetup || (shouldRequestData && isLoading)) return <Loading />

  return (
    <Context.Provider
      value={{
        category: data?.value?.flow.category ?? FlowCategory.hiring,
        subcategory: data?.value?.flow.subcategory ?? FlowSubcategory.clt,
        cardId: data?.value?.card._id ?? "",
        candidateId: (data?.value?.card as HiringCard)?.candidateId ?? "",
        companyId: data?.value?.card.companyId ?? "",
        employeeId: (data?.value?.card as ResignationCard)?.employeeId ?? "",
        name: tokenData.name,
        type: tokenData.type,
        token: tokenData.token,
      }}
    >
      {children ? children : <Outlet />}
    </Context.Provider>
  )
}

export { ContextProvider, Context }
