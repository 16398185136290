import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as yup from "yup"
import {
  Container,
  RightContainer,
  LeftContainer,
  RightSubContainer,
  ActionFooterContainer,
} from "./styles"
import {
  TextField,
  Typography,
  Icons,
  LinkButton,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { Context } from "src/context"
import { signingContext } from "../.."
import { SignType } from "server/src/types"
import { trpc } from "src/api/trpc"
import dispatchToast from "@Utils/dispatchToast"
import i18n from "src/i18n"
import { validateCPF } from "@Utils/index"
import { useTranslation } from "react-i18next"
import { Box } from "@Components"
import { useTheme } from "styled-components"

const SignData = () => {
  const navigate = useNavigate()
  const {
    subcategory,
    type: userType,
    cardId,
    candidateId,
    companyId,
    category,
    name,
  } = useContext(Context)

  const theme = useTheme()

  const { confirmationData, handleSetConfirmationData, signData } =
    useContext(signingContext)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.signing.signData",
  })

  const trackUserList = {
    [SignType.Candidate]: trackList?.[subcategory]?.employee || "",
    [SignType.Company]: trackList?.[subcategory]?.company || "",
    [SignType.Institution]: trackList?.[subcategory]?.institution,
  }

  const signature = signData.contractSignatures.find(
    (s) => s.type === userType && s.name === name
  )

  const { data: employee } = trpc.platform.getEmployee.useQuery(
    { employeeId: signature?.employeeId ?? "" },
    {
      enabled:
        (userType === SignType.Company || userType === SignType.Employee) &&
        !!signature?.employeeId,
    }
  )

  useEffect(() => {
    if (employee) {
      formik.setValues({
        name: employee.name,
        email:
          userType === SignType.Employee ? formik.values.email : employee.email,
        documentNumber: employee.documentNumber,
      })
    }
  }, [employee])

  const { data: candidateData } = trpc.wizard.getCandidateData.useQuery(
    { candidateId, cardId, companyId },
    { enabled: userType === SignType.Candidate }
  )

  useEffect(() => {
    if (candidateData) {
      formik.setValues({
        name: candidateData.candidate.name ?? formik.values.name,
        email: candidateData.candidate.email ?? formik.values.email,
        documentNumber:
          candidateData.candidate.documentNumber ??
          formik.values.documentNumber,
      })
    }
  }, [candidateData])

  const { mutateAsync: submitSignContract, isLoading: isSubmitLoading } =
    trpc.wizard.submitSignContractV2.useMutation({
      onError: (err) => {
        dispatchToast({
          type: "error",
          content:
            err.data?.userFriendlyError.message || i18n.t("error.defaultError"),
        })
      },
    })

  const track = trackUserList?.[userType]

  const validationSchema = yup.object({
    name: yup.string().required(t("form.validation.requiredName")),
    documentNumber: yup
      .string()
      .test("validCPF", t("form.validation.validDocumentNumber"), validateCPF)
      .required(t("form.validation.requiredDocumentNumber")),
    email: yup
      .string()
      .email(t("form.validation.validEmail"))
      .required(t("form.validation.requiredEmail")),
  })

  const formik = useFormik({
    initialValues: {
      name: confirmationData?.name || signature?.name || "",
      documentNumber: confirmationData?.documentNumber || "",
      email: confirmationData?.email || signature?.email || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (track) createSegmentTrack({ track: track.signContractSign })

      handleSetConfirmationData({
        name: values.name,
        documentNumber: values.documentNumber,
        email: values.email,
      })

      const { routeRedirect } = await submitSignContract({
        cardId: signData.cardId,
        category: category,
        name: name,
        signType: userType as SignType,
        confirmationData: {
          name: values.name,
          documentNumber: values.documentNumber,
          email: values.email,
        },
      })

      navigate(`../wizard/signing/${routeRedirect}`)
    },
  })

  return (
    <Container>
      <LeftContainer>
        <Box flexDirection="column" gap="xs">
          <Typography variant="headline7" color="secondary.50">
            {t("title")}
          </Typography>
          <Typography variant="body3" color="neutral.50">
            {t("description")}
          </Typography>
        </Box>
      </LeftContainer>
      <RightContainer>
        <RightSubContainer>
          <Box flexDirection="column" $width={"100%"} gap="xs">
            <Typography variant="headline8" color="neutral.30">
              {t("personalData")}
            </Typography>
            <div>
              <TextField
                id={"name"}
                name={"name"}
                label={t("form.fields.name")}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name ? formik.errors.name : ""}
                fullWidth
              />
            </div>
            <div>
              <TextField
                id={"documentNumber"}
                name={"documentNumber"}
                label={t("form.fields.documentNumber")}
                imaskProps={{ mask: "000.000.000-00" }}
                value={formik.values.documentNumber}
                inputProps={{ maxLength: 14 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.documentNumber &&
                  Boolean(formik.errors.documentNumber)
                }
                helperText={
                  formik.touched.documentNumber
                    ? formik.errors.documentNumber
                    : ""
                }
                fullWidth
              />
            </div>
            <div>
              <TextField
                id={"email"}
                name={"email"}
                label={t("form.fields.email")}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email ? formik.errors.email : ""}
                fullWidth
              />
            </div>
          </Box>
        </RightSubContainer>
        <ActionFooterContainer>
          <LinkButton
            variant="default"
            onClick={() => {
              if (track)
                createSegmentTrack({ track: track?.signContractReturn })

              navigate(`../wizard/signing/visualizeContract`)
            }}
          >
            {t("buttons.return")}
          </LinkButton>

          <Button
            type={"submit"}
            variant="primary"
            size="large"
            loading={isSubmitLoading}
            onClick={() => formik.handleSubmit()}
          >
            {t("buttons.sign")}
            <Icons
              name="IconArrowRight"
              fill="transparent"
              color={theme.colors.neutral[100]}
            />
          </Button>
        </ActionFooterContainer>
      </RightContainer>
    </Container>
  )
}

export default SignData
