import { useContext } from "react"
import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "react-i18next"

import {
  Container,
  ProposalTitle,
  Description,
  Avatar,
  LogoArea,
} from "./styles"

import Logo from "../../../../assets/logo_flash.svg"
import { Context } from "src/context"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { StartSigningContentProps } from "."
import { SignType } from "server/src/types"

export const WitnessContent = ({ signature }: StartSigningContentProps) => {
  const { subcategory } = useContext(Context)
  const navigate = useNavigate()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const candidateTypeText =
    signature.type === SignType.employee ? "colaborador" : "candidato"

  return (
    <>
      <Container>
        <Avatar>{signature.name.substring(0, 1)}</Avatar>
        <ProposalTitle variant={"headline6"}>
          <span>
            Hora de assinar o contrato do(a) {candidateTypeText}(a){" "}
            <span style={{ textTransform: "capitalize" }}>
              {signature.name.split(" ")?.[0]}
            </span>
          </span>
        </ProposalTitle>

        <Description variant={"body3"}>
          Por favor assine o documento o mais rápido possível.
        </Description>
        <Button
          onClick={() => {
            const track = trackList?.[subcategory]?.employee?.analyzeDocument
            if (track)
              createSegmentTrack({
                track: track,
              })

            navigate(`../wizard/signing/visualizeContract`, {
              replace: true,
            })
          }}
          variant="primary"
          size="large"
          style={{ alignSelf: "center" }}
        >
          {t("signing.start.buttonLabel")}
        </Button>
      </Container>

      <LogoArea>
        {t("signing.start.developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </>
  )
}
