import React from "react"
import { getFromLS, setInLS, parseJWT } from "@flash-hros/utility"
import { removeTokenFromURL, shallowEqual } from "@Utils/index"
import { Outlet } from "react-router-dom"
import { Loading } from "@Components"

export interface TokenData {
  iss: string
  cardId: string
  employeeId: string
  companyId: string
  createdBy: string
  aud: string
  iat: number
  exp: number
  token: string
}

interface IContext extends TokenData {}

const ResignationContext = React.createContext<IContext>(null!)

const ResignationContextProvider = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const [hasSetup, setHasSetup] = React.useState<boolean>(false)
  const [tokenData, setTokenData] = React.useState<TokenData>({
    iss: "",
    cardId: "",
    companyId: "",
    employeeId: "",
    createdBy: "",
    aud: "",
    iat: 0,
    exp: 0,
    token: "",
  })

  React.useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const tokenFromURL = urlParams.get("token")

      const token = tokenFromURL || getFromLS("wizard-token")
      if (tokenFromURL) {
        setInLS({ key: "wizard-token", value: token })
        removeTokenFromURL(urlParams)
      }

      setHasSetup(true)

      if (!token) return

      const decodedToken: TokenData = parseJWT({ token })

      const parsedToken: TokenData = {
        aud: decodedToken.aud,
        cardId: decodedToken.cardId,
        companyId: decodedToken.companyId,
        createdBy: decodedToken.createdBy,
        employeeId: decodedToken.employeeId,
        exp: decodedToken.exp,
        iat: decodedToken.iat,
        iss: decodedToken.iss,
        token,
      }

      if (shallowEqual(token, parsedToken)) return
      setTokenData(parsedToken)
    } catch (e) {
      window.location.href = "/wizard/invalid"
    }
  }, [])

  if (!hasSetup || !tokenData) return <Loading />

  return (
    <ResignationContext.Provider value={tokenData}>
      {children ? children : <Outlet />}
    </ResignationContext.Provider>
  )
}

export { ResignationContextProvider, ResignationContext }
