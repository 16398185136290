import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

import {
  Container,
  ProposalTitle,
  ProposalSubtitle,
  Description,
  Avatar,
  LogoArea,
  MainContainer,
  PageContents,
} from "./styles"

import Logo from "../../assets/logo_flash.svg"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect } from "react"
import i18n from "../../i18n"
import { useTranslation } from "react-i18next"

export enum ContentsAvailable {
  Aso = "aso",
  Signing = "signing",
  Documents = "documents",
  ResignationAso = "resignationAso",
  ResignationLetter = "resignationLetter",
}

type Content = {
  [key in ContentsAvailable]: {
    title: string
    subtitle: string
    description: string
  }
}
const contents: Content = {
  [ContentsAvailable.ResignationAso]: {
    title: i18n.t("page.finished.resignationAso.title"),
    subtitle: i18n.t("page.finished.resignationAso.subtitle"),
    description: i18n.t("page.finished.resignationAso.description"),
  },
  [ContentsAvailable.Aso]: {
    title: i18n.t("page.finished.aso.title"),
    subtitle: i18n.t("page.finished.aso.subtitle"),
    description: i18n.t("page.finished.aso.description"),
  },
  [ContentsAvailable.Documents]: {
    title: i18n.t("page.finished.documents.title"),
    subtitle: i18n.t("page.finished.documents.subtitle"),
    description: i18n.t("page.finished.documents.description"),
  },
  [ContentsAvailable.Signing]: {
    title: i18n.t("page.finished.signing.title"),
    subtitle: i18n.t("page.finished.signing.subtitle"),
    description: i18n.t("page.finished.signing.description"),
  },
  [ContentsAvailable.ResignationLetter]: {
    title: i18n.t("page.finished.resignationLetter.title"),
    subtitle: i18n.t("page.finished.resignationLetter.subtitle"),
    description: i18n.t("page.finished.resignationLetter.description"),
  },
}

const Finished = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const type = searchParams.get("type")

  const [t] = useTranslation("translations", {
    keyPrefix: "page.finished",
  })

  useEffect(() => {
    if (
      type &&
      !Object.values(ContentsAvailable).includes(type as ContentsAvailable)
    ) {
      navigate("/wizard/invalid?type=invalid")
    }
  }, [type])

  if (!type) return null
  const content = contents[type]

  return (
    <MainContainer>
      <PageContents>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <>
            <Container>
              <Avatar>
                <Icons
                  name="IconMoodHappy"
                  style={{
                    width: "70px",
                    height: "70px",
                    fill: "transparent",
                    color: "#fff",
                  }}
                />
              </Avatar>
              <ProposalTitle>{content?.title}</ProposalTitle>
              <ProposalSubtitle>{content?.subtitle}</ProposalSubtitle>
              <Description>{content?.description}</Description>
            </Container>
          </>
          <LogoArea>
            {t("developedBy")}
            <Logo style={{ marginLeft: "8px" }} />
          </LogoArea>
        </div>
      </PageContents>
    </MainContainer>
  )
}

export default Finished
