import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  Skeleton,
  Typography,
  LinkButton,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { trpc } from "src/api/trpc"
import {
  Dependent,
  Candidate,
  HiringCard,
  ConfirmationDependent,
} from "server/src/types"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { Context } from "src/context"

import ArrowRight from "../../../../assets/arrow_right.svg"
import {
  Container,
  RightContainer,
  LeftContainer,
  StepTitle,
  SubTitle as StyledSubtitle,
  ButtonContainer,
  Wrapper,
} from "./styles"

import CandidateData from "./CandidateData"
import CandidateDocuments from "./CandidateDocuments"
import CandidateDependents from "./CandidateDependents"
import dispatchToast from "@Utils/dispatchToast"

const ConfirmationStep = ({
  candidate,
  card,
}: {
  candidate: Candidate
  card: HiringCard
}) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { subcategory } = useContext(Context)
  const theme = useTheme()
  const navigate = useNavigate()
  const primary = theme?.colors?.primary

  const {
    data: confirmationData,
    isLoading,
    isRefetching,
  } = trpc.wizard.getCandidateConfirmationData.useQuery({
    candidateId: candidate._id,
    formConfigId: card.formConfigId,
    extensionId: card.formExtensionId,
  })

  const {
    mutateAsync: submitCandidateDocuments,
    isLoading: isSubmitingDocuments,
  } = trpc.wizard.submitCandidateDocuments.useMutation()

  const { mutateAsync: removeLimitDate } =
    trpc.hiring.removeLimitDate.useMutation()

  const handleSubmit = async () => {
    const track = trackList?.[subcategory]?.employee?.sendDocReviewContinue
    if (track)
      createSegmentTrack({
        track: track,
      })

    const hasRequiredDocumentUnfilled = confirmationData?.documentFields.some(
      (doc) =>
        doc.fields.some(
          (field: any) => field.fieldRule?.required && !field.value
        )
    )
    if (
      hasRequiredDocumentUnfilled ||
      confirmationData?.documentFields.some(
        (doc) => doc.validation?.status === "declined"
      )
    ) {
      dispatchToast({
        type: "error",
        content:
          "Por favor, preencha todos os documentos requiridos na etapa de Documentos da Pessoa",
      })
      return
    }

    if (
      confirmationData?.dependents &&
      confirmationData?.dependents.length > 0
    ) {
      const hasRequiredDependentUnfilled = confirmationData?.dependents?.some(
        (dependent) =>
          dependent.documents.some((doc) =>
            doc.fields.some(
              (field: any) => field.fieldRule?.required && !field.value
            )
          )
      )
      if (
        hasRequiredDependentUnfilled ||
        confirmationData?.dependents?.some((dep) =>
          dep.documents.some((doc) => doc.validation?.status === "declined")
        )
      ) {
        dispatchToast({
          type: "error",
          content:
            "Por favor, preencha todos os documentos requiridos dos Dependentes",
        })
        return
      }
    }

    submitCandidateDocuments({
      flowCardId: card._id,
    })
      .then(async () => {
        const limitDate = card.limitDates.find((limitDate) =>
          limitDate.step.includes("send-documents")
        )
        if (limitDate) {
          await removeLimitDate({
            cardId: card._id,
            step: limitDate.step,
          })
        }
      })
      .catch((err) => {
        dispatchToast({
          type: "error",
          content: "Alguma coisa aconteceu ao finalizar o envio de documentos",
        })
      })
      .finally(() => navigate("../wizard/finished?type=documents"))
  }

  return (
    <>
      <Container>
        <LeftContainer>
          <StepTitle>{t("confirmationStep.titleLabel")}</StepTitle>
          <StyledSubtitle>
            Parabéns, você preencheu toda a documentação necessária. 😀 Agora é
            a hora de revisar as informações, só prossiga depois de confirmar
            seus dados.
          </StyledSubtitle>
        </LeftContainer>
        {isLoading || isRefetching ? (
          <RightContainer>
            <Skeleton variant="rectangular" height={"100%"} />
          </RightContainer>
        ) : (
          <RightContainer>
            {confirmationData && (
              <Wrapper>
                <CandidateData
                  card={card}
                  basicData={confirmationData.candidateFields}
                />
                <CandidateDocuments
                  card={card}
                  documents={confirmationData.documentFields}
                />
                {confirmationData.dependents && (
                  <CandidateDependents
                    card={card}
                    dependents={
                      confirmationData.dependents as ConfirmationDependent[]
                    }
                  />
                )}
              </Wrapper>
            )}

            <ButtonContainer
              style={{ paddingTop: "60px", paddingBottom: "20px" }}
            >
              <LinkButton
                variant="default"
                onClick={() => {
                  const track =
                    trackList?.[subcategory]?.employee?.sendDocReviewReturn
                  if (track)
                    createSegmentTrack({
                      track: track,
                    })

                  navigate("../wizard/hiring/dependents", {
                    replace: true,
                  })
                }}
              >
                Voltar
              </LinkButton>
              <div>
                <Button
                  variant="primary"
                  size="large"
                  onClick={handleSubmit}
                  disabled={isSubmitingDocuments}
                  style={{
                    width: 311,
                  }}
                >
                  Enviar
                  <ArrowRight style={{ color: "#fff", marginLeft: "7px" }} />
                </Button>
              </div>
            </ButtonContainer>
          </RightContainer>
        )}
      </Container>
    </>
  )
}

export default ConfirmationStep
