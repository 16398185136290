import {
  Icons,
  Typography,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  MainContainer,
  FormContainer,
  Container,
  LeftContainer,
  StyledButton,
} from "./styles"
import { Box, Navbar, Upload } from "@Components"
import { FileType, StorageGatewayModules } from "server/src/types"
import { trpc } from "src/api/trpc"
import dispatchToast from "@Utils/dispatchToast"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

type FormValues = {
  file?: FileType
}

type ContentProps = {
  cardId: string
}

export const Content = ({ cardId }: ContentProps) => {
  const isMobile = useMediaQuery("(max-width: 576px)")
  const navigate = useNavigate()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationLetter.content",
  })

  const folder = `${cardId}/formalization-letter`

  const { mutateAsync: uploadResignationLetter, isLoading: isSubmiting } =
    trpc.resignation.uploadResignationLetter.useMutation({
      onError(error) {
        dispatchToast({
          type: "error",
          content:
            error.shape?.data.userFriendlyError.message ?? t("error.generic"),
        })
      },
      onSuccess() {
        navigate("/wizard/finished?type=resignationLetter")
      },
    })

  const formik = useFormik<FormValues>({
    initialValues: {
      file: undefined,
    },
    onSubmit: async (values) => {
      if (!values.file) {
        dispatchToast({
          type: "error",
          content: t("error.generic"),
        })
        return
      }

      await uploadResignationLetter({ cardId, filePath: values.file.path })
    },
  })

  const handleFileUpload = async ({ file }: { file: FileType }) => {
    formik.setValues({ file })
  }
  const handleFileRemove = async () => {
    formik.setValues({ file: undefined })
  }

  return (
    <MainContainer>
      <Navbar />

      <Container>
        <LeftContainer>
          <Typography variant="headline7" color="secondary.50">
            {t("title")}
          </Typography>
          <Typography variant="body3" color="neutral.50">
            {t("description")}
          </Typography>
        </LeftContainer>

        <Box $width={"100%"} flexDirection="column" gap="m">
          <FormContainer>
            <Box flexDirection="column" gap="xs5">
              <Typography variant="headline8" color="neutral.30">
                {t("subtitle")}
              </Typography>
              <Typography variant="body4" color="neutral.50">
                {t("subtitleDescription")}
              </Typography>
            </Box>

            <Upload.Root
              folder={folder}
              label={t("uploadLabel")}
              placeholder={t("uploadLabel")}
              value={formik.values.file}
              onUpload={async (file: FileType) => {
                handleFileUpload({ file })
              }}
              accept={["jpg", "jpeg", "png", "pdf"]}
              module={StorageGatewayModules.Resignation}
            >
              <Upload.Action
                icon={"IconTrash"}
                onClick={() => {
                  handleFileRemove()
                }}
              />
            </Upload.Root>
          </FormContainer>

          {!isMobile && (
            <StyledButton
              variant="primary"
              size="large"
              onClick={() => formik.handleSubmit()}
              disabled={!formik.values.file}
              loading={isSubmiting}
            >
              {t("buttonNext")}
              <Icons name="IconArrowRight" fill="transparent" />
            </StyledButton>
          )}
        </Box>
      </Container>

      {isMobile && (
        <StyledButton
          variant="primary"
          size="large"
          onClick={() => formik.handleSubmit()}
          disabled={!formik.values.file}
          loading={isSubmiting}
        >
          {t("buttonNext")}
          <Icons name="IconArrowRight" fill="transparent" />
        </StyledButton>
      )}
    </MainContainer>
  )
}
