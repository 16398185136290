import { useState, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import ArrowRight from "../../../../assets/arrow_right.svg"

import {
  Button,
  Icons,
  LinkButton,
  Skeleton,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

import { useTheme } from "styled-components"

import { Context } from "../../../../context"

import {
  Container,
  RightContainer,
  LeftContainer,
  StepTitle,
  SubTitle as StyledSubTitle,
  ToggleContainer,
  ButtonContained,
  ButtonContainer,
} from "./styles"
import dispatchToast from "../../../../utils/dispatchToast"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { trpc } from "src/api/trpc"
import { Candidate, ConfirmationDependent, HiringCard } from "server/src/types"
import RenderDependents from "./RenderDependents"
import NewDependent from "./NewDependent"
import _ from "lodash"
import { hasRequiredFieldWithoutValue } from "@Utils/index"

const DependentsStep = ({
  candidate,
  card,
}: {
  candidate: Candidate
  card: HiringCard
}) => {
  const [candidateHasDependents, setCandidateHasDependents] = useState(false)
  const [addNewDependent, setAddNewDependent] = useState(false)
  const [fieldErrors, setFieldErrors] = useState<any>([])
  const [dependents, setDependents] = useState<ConfirmationDependent[]>([])
  const [showErrors, setShowErrors] = useState<boolean>(false)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })
  const { subcategory } = useContext(Context)
  const navigate = useNavigate()
  const theme = useTheme()

  const { isLoading, isRefetching } =
    trpc.wizard.getDependentDocuments.useQuery<ConfirmationDependent[]>(
      {
        candidateId: candidate._id,
        formConfigId: card.formConfigId,
        extensionId: card.formExtensionId,
      },
      {
        cacheTime: 0,
        onSuccess(data) {
          const hasDependents = data.length > 0
          if (candidateHasDependents && !hasDependents) setAddNewDependent(true)
          if (hasDependents) setCandidateHasDependents(true)
          setDependents(data)
        },
      }
    )

  useEffect(() => {
    if (dependents?.length === 0) return
    let depsWithFieldErrors = _.cloneDeep(dependents)
    depsWithFieldErrors = depsWithFieldErrors?.map((d) => {
      const newDep: any = _.cloneDeep(d)
      const filteredDocuments = newDep.documents?.filter(
        (section) => section.enabled
      )
      newDep.documents = hasRequiredFieldWithoutValue(filteredDocuments)
      return newDep
    })

    setFieldErrors(depsWithFieldErrors)
  }, [dependents])

  const handleSubmit = () => {
    const track = trackList?.[subcategory]?.employee?.sendDocDependentContinue
    if (track)
      createSegmentTrack({
        track: track,
      })

    let depsWithFieldErrors = _.cloneDeep(dependents)
    depsWithFieldErrors = depsWithFieldErrors?.map((d) => {
      const newDep: any = _.cloneDeep(d)
      const filteredDocuments = newDep.documents?.filter(
        (section) => section.enabled
      )
      newDep.documents = hasRequiredFieldWithoutValue(filteredDocuments)
      return newDep
    })

    setFieldErrors(depsWithFieldErrors)

    const count: any = depsWithFieldErrors
      ?.map((d) => {
        return d.documents.reduce((prev, curr) => {
          return [...prev, ...curr.fields]
        }, [] as any[])
      })
      .flat(Infinity).length

    if (count > 0) {
      setShowErrors(true)
      dispatchToast({
        type: "error",
        content: `${count} campo${count > 1 ? "s" : ""} obrigatório${
          count > 1 ? "s" : ""
        } não fo${count > 1 ? "ram" : "i"} preenchido${count > 1 ? "s" : ""}!`,
      })
      return
    }

    navigate(`../wizard/hiring/confirmation`, {
      replace: true,
    })
  }

  return (
    <Container>
      <LeftContainer>
        <StepTitle>{t("dependentsStep.titleLabel")}</StepTitle>
        <StyledSubTitle>
          Podem ser considerados dependentes cônjuges ou companheiros(as),
          filhos não emancipados de qualquer condição, menores de vinte e um
          anos ou inválidos.
        </StyledSubTitle>
      </LeftContainer>

      {isLoading || isRefetching ? (
        <RightContainer>
          <Skeleton variant="rectangular" height={"100%"} />
        </RightContainer>
      ) : (
        <RightContainer>
          <ToggleContainer>
            <Toggle
              checked={candidateHasDependents}
              onChange={(e) => {
                const track =
                  trackList?.[subcategory]?.employee?.sendDocDependentToggle
                if (track)
                  createSegmentTrack({
                    track: track,
                  })

                if (e.target.checked && !dependents?.length)
                  setAddNewDependent(true)

                setCandidateHasDependents(e.target.checked || false)
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "12px",
              }}
            >
              <Typography
                variant="body3"
                style={{ color: "#86797F", fontWeight: 700 }}
              >
                Adicionar dependentes
              </Typography>

              <Typography variant="body4" style={{ color: "#A19199" }}>
                Ative para preencher os documentos de cada dependente. Caso não
                possua, avance para a próxima etapa.
              </Typography>
            </div>
          </ToggleContainer>

          {candidateHasDependents && (
            <>
              {dependents?.length > 0 && (
                <RenderDependents
                  card={card}
                  dependents={dependents}
                  setDependents={setDependents}
                  fieldErrors={fieldErrors}
                  showErrors={showErrors}
                />
              )}

              {addNewDependent && (
                <NewDependent
                  candidateId={candidate._id}
                  successCallback={() => setAddNewDependent(false)}
                  handleDeleteLocal={() => setAddNewDependent(false)}
                />
              )}

              <ButtonContained
                onClick={() => {
                  const track =
                    trackList?.[subcategory]?.employee?.sendDocDependentAdd
                  if (track)
                    createSegmentTrack({
                      track: track,
                    })

                  if (addNewDependent === false)
                    setAddNewDependent((prev) => !prev)
                }}
              >
                <Typography variant="body3">
                  Adicionar novo dependente
                </Typography>

                <Icons
                  name="Add"
                  style={{ marginLeft: "13px" }}
                  color={theme?.colors?.primary}
                />
              </ButtonContained>
            </>
          )}

          <ButtonContainer
            style={{ paddingTop: "60px", paddingBottom: "20px" }}
          >
            <LinkButton
              variant="default"
              onClick={() => {
                const track =
                  trackList?.[subcategory]?.employee?.sendDocDependentAdd
                if (track)
                  createSegmentTrack({
                    track: track,
                  })

                navigate(`../wizard/hiring/candidate`, {
                  replace: true,
                })
              }}
            >
              Voltar
            </LinkButton>

            <div>
              <Button
                variant="primary"
                size="large"
                onClick={handleSubmit}
                style={{
                  width: 311,
                }}
              >
                Continuar
                <ArrowRight style={{ color: "#fff", marginLeft: "7px" }} />
              </Button>
            </div>
          </ButtonContainer>
        </RightContainer>
      )}
    </Container>
  )
}

export default DependentsStep
