import { useTranslation } from "react-i18next"
import { Container, Avatar, LogoArea } from "./styles"
import Logo from "../../../../assets/logo_flash.svg"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { Box, CenterTypography } from "@Components"

const Signed = () => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.signing.signed",
  })
  return (
    <Box
      $height={"100%"}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Container>
        <Avatar>
          <Icons
            name="IconMoodHappy"
            style={{
              width: "64px",
              height: "64px",
              fill: "transparent",
              color: "#fff",
            }}
          />
        </Avatar>
        <Box flexDirection="column" gap="xs" $pb="m">
          <CenterTypography variant={"headline7"} color="flamingo-pink">
            {t("title")}
          </CenterTypography>
          <CenterTypography variant={"body3"} color="neutral.40">
            {t("description")}
          </CenterTypography>
        </Box>
      </Container>
      <LogoArea>
        {t("developedBy")}
        <Logo />
      </LogoArea>
    </Box>
  )
}

export default Signed
