import { useMemo } from "react"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { Mobile } from "./Mobile"
import { Desktop } from "./Desktop"

export type HeaderProps = {
  companyName: string
  companyLogo?: string
}

export const Header = (props: HeaderProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  const HeaderRender = useMemo(
    () => (isMobile ? <Mobile {...props} /> : <Desktop {...props} />),
    [isMobile]
  )

  return HeaderRender
}
