import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2"
import "../src/i18n"
import { AppRouter } from "./routes"

const App = () => {
  return (
    <ThemeProvider>
      <AppRouter />
    </ThemeProvider>
  )
}

export default App
