import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import {
  Finished,
  Invalid,
  MainPage,
  Proposal,
  Signing,
  AdmissionExam,
  ResignationExam,
  ResignationLetter,
} from "../pages"
import AuthGuard from "./AuthGuard"
import { useContext } from "react"
import {
  Context,
  ContextProvider,
  ResignationContext,
  ResignationContextProvider,
} from "src/context"

const AuthGuardHiring = ({ children }: { children?: JSX.Element }) => {
  const { token } = useContext(Context)
  return <AuthGuard token={token}>{children}</AuthGuard>
}

const AuthGuardResignation = ({ children }: { children?: JSX.Element }) => {
  const { token } = useContext(ResignationContext)
  return <AuthGuard token={token}>{children}</AuthGuard>
}

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ContextProvider />}>
          <Route element={<AuthGuardHiring />}>
            <Route path="/wizard/hiring/:route" element={<MainPage />} />
            <Route path="/wizard/proposal/:route" element={<Proposal />} />
            <Route path="/wizard/signing/:route" element={<Signing />} />
            <Route path="/wizard/aso" element={<AdmissionExam />} />
            <Route
              path="/wizard/resignationAso"
              element={<ResignationExam />}
            />
          </Route>
        </Route>

        <Route element={<ResignationContextProvider />}>
          <Route element={<AuthGuardResignation />}>
            <Route
              path="/wizard/resignation-letter"
              element={<ResignationLetter />}
            />
          </Route>
        </Route>

        <Route path="/wizard/finished" element={<Finished />} />
        <Route path="/wizard/invalid" element={<Invalid />} />
        <Route
          path="*"
          element={<Navigate to="/wizard/invalid?type=invalid" replace />}
        />
      </Routes>
    </BrowserRouter>
  )
}
