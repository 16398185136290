import styled, { css, DefaultTheme } from "styled-components"
import { UploadLoadingStates } from "."

const mapStyleState = (theme: DefaultTheme) => ({
  [UploadLoadingStates.Progress]: {
    backgroundColor: theme.colors.secondary[99],
    border: `${theme.borders.width.xs2} solid ${theme.colors.secondary[70]}`,
  },
  [UploadLoadingStates.Finished]: {
    backgroundColor: theme.colors.neutral[100],
    border: `${theme.borders.width.xs2} solid ${theme.colors.feedback.success[40]}`,
  },
})

export const Container = styled.div<{ state: UploadLoadingStates }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.m};
  padding: ${({ theme }) => theme.spacings.m};

  ${({ theme, state }) => {
    const style = mapStyleState(theme)
    return css`
      background-color: ${style[state].backgroundColor};
      border: ${style[state].border};
    `
  }}
`

export const ProgressContainer = styled.div`
  width: 80%;
`
