import { ReactNode, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  dayjs,
  Icons,
  Loader,
  IconButton as IconButtonComponent,
  Typography,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate } from "react-router-dom"

import {
  ButtonArea,
  Container,
  ContentArea,
  HtmlItemSimpleText,
  ItemArea,
  ItemIcon,
  ItemSimpleText,
  ItemTitle,
  List,
  StepTitle,
  StyledLinkButton,
  StyledPOneLine,
  StyledSubtitle,
  StyledSubtitleContainer,
  StyledTextArea,
  Title,
  UploadFileName,
  UploadIconContainer,
  UploadPreview,
  UploadPreviewArea,
  LeftContainer,
  RightContainer,
  SubTitle,
  TextDeny,
  TextConfirm,
} from "./styles"
import { trpc } from "src/api/trpc"
import { Context } from "src/context"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { Company, Candidate, HiringCard } from "server/src/types"
import dispatchToast from "@Utils/dispatchToast"
import { removeDropzoneTimestamp } from "@Utils/index"

const Mobile = ({
  company,
  card,
  candidate,
}: {
  company: Company
  card: HiringCard
  candidate: Candidate
}) => {
  const [hasRefuse, setHasRefuse] = useState(false)
  const [refuseReason, setRefuseReason] = useState("")
  const [showMore, setShowMore] = useState({ more: false, type: "" })

  const navigate = useNavigate()
  const { subcategory } = useContext(Context)

  const { mutateAsync: acceptProposal, isLoading: isAcceptingProposal } =
    trpc.wizard.acceptProposal.useMutation({
      onSuccess: () => {
        navigate(`../wizard/proposal/accepted`, {
          replace: true,
        })
      },
    })
  const { mutateAsync: denyProposal, isLoading: isRefusingProposal } =
    trpc.wizard.denyProposal.useMutation({
      onSuccess: () => {
        navigate(`../wizard/proposal/denied`, {
          replace: true,
        })
      },
    })
  const { mutateAsync: removeLimitDate } =
    trpc.hiring.removeLimitDate.useMutation()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const Item = ({
    icon,
    title,
    children,
  }: {
    icon: any
    title: string
    children?: ReactNode
  }) => {
    return (
      <ItemArea>
        <ItemIcon>
          <Icons
            name={icon}
            fill={"transparent"}
            style={{ width: "24px", height: "24px" }}
          />
        </ItemIcon>
        <div>
          <ItemTitle>{title}</ItemTitle>
          {children}
        </div>
      </ItemArea>
    )
  }

  const handleAccept = async () => {
    const track = trackList?.[subcategory]?.employee?.proposalAccept
    if (track)
      createSegmentTrack({
        track: track,
      })

    acceptProposal({ flowCardId: card._id })
      .then(async () => {
        const limitDate = card.limitDates.find((limitDate) =>
          limitDate.step.includes("proposal-letter")
        )
        if (limitDate) {
          await removeLimitDate({
            cardId: card._id,
            step: limitDate.step,
          })
        }
      })
      .catch((err: any) => {
        console.error(err)
        dispatchToast({
          type: "error",
          content: t("proposal.errorMessage"),
        })
      })
  }

  const handleDeny = async () => {
    if (hasRefuse) {
      const track = trackList?.[subcategory]?.employee?.proposalRefuse
      if (track)
        createSegmentTrack({
          track: track,
        })

      await denyProposal({
        flowCardId: card._id,
        refuseReason,
        version: card.version,
      })
    } else setHasRefuse(true)
  }

  const _formatCurrency = function (amount) {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
  }

  const renderPreviewComponent = (fieldName) => {
    const attach = candidate.proposalLetterInfo?.[fieldName]
    const sliced = attach.slice(
      0,
      showMore.more && showMore.type === fieldName ? attach.length : 2
    )
    const getTotal = attach.length - 2

    return (
      <>
        {sliced.map(({ key = "", value = "", path = "" }, index) => {
          const lastIndexName = key ? key.lastIndexOf("/") : ""
          const nameOfArchive =
            key && lastIndexName
              ? removeDropzoneTimestamp(key.substring(lastIndexName + 1))
              : "Nome não identificado"

          return (
            <div key={index} style={{ marginTop: "24px" }}>
              <div
                role="presentation"
                onClick={async (e) => {
                  e.stopPropagation()
                }}
              >
                <UploadPreview>
                  <UploadPreviewArea>
                    <UploadIconContainer>
                      <Icons
                        name={"IconFile"}
                        style={{ fill: "transparent" }}
                      />
                    </UploadIconContainer>
                    <UploadFileName>
                      <StyledPOneLine numberOfLines={1}>
                        {nameOfArchive}
                      </StyledPOneLine>
                    </UploadFileName>
                  </UploadPreviewArea>

                  <div style={{ display: "flex" }}>
                    <IconButtonComponent
                      size="medium"
                      variant="filled"
                      icon="IconDownload"
                      onClick={async () => {
                        const readFile = await fetch(value)
                        const fileToArrayBuffer = await readFile.arrayBuffer()
                        const res = URL.createObjectURL(
                          new Blob([fileToArrayBuffer])
                        )
                        const a = document.createElement("a")
                        a.href = res
                        a.download = nameOfArchive
                        a.click()
                      }}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </UploadPreview>
              </div>
            </div>
          )
        })}
        {getTotal >= 1 ? (
          <StyledLinkButton
            variant="default"
            onClick={() => {
              setShowMore({ more: !showMore.more, type: fieldName })
            }}
            style={{ marginTop: "17px" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant={"body3"} style={{ fontWeight: 700 }}>
                {showMore.more
                  ? "Ver menos"
                  : `Ver mais ${getTotal} anexo${getTotal > 1 ? "s" : ""}`}
              </Typography>
              <Icons
                name={showMore.more ? "IconChevronUp" : "IconChevronDown"}
                style={{ fill: "transparent", marginLeft: "4px" }}
              />
            </div>
          </StyledLinkButton>
        ) : (
          <></>
        )}
      </>
    )
  }

  const renderInternshipComponent = () => {
    let workload = "Não consta"
    let workship = "Não consta"

    switch (candidate.internship?.workload) {
      case "twenty-hours-week":
        workload = "20 horas semanais"
        break
      case "thiry-hours-week":
        workload = "30 horas semanais"
        break
      case "forty-hours-week":
        workload = "40 horas semanais"
        break
      default:
        break
    }

    switch (candidate.internship?.workship) {
      case "morning":
        workship = "Manhã"
        break
      case "afternoon":
        workship = "Tarde"
        break
      default:
        break
    }

    return (
      <>
        <Item icon="IconUsers" title={"Líder direto"}>
          <ItemSimpleText>
            {candidate?.leader?.name ?? "Não consta"}
          </ItemSimpleText>
        </Item>
        <Item icon="IconClockHour4" title={"Carga horária"}>
          <ItemSimpleText>{workload}</ItemSimpleText>
        </Item>
        <Item icon="IconSun" title={"Período de trabalho"}>
          <ItemSimpleText>{workship}</ItemSimpleText>
        </Item>
      </>
    )
  }

  const startDate =
    subcategory === "internship"
      ? dayjs(candidate.internship?.startDate).format("DD/MM/YYYY")
      : dayjs(candidate.proposalLetterInfo?.hiringDate).format("DD/MM/YYYY")

  return (
    <Container>
      <LeftContainer>
        <StepTitle variant={"headline7"}>
          {t("proposal.confirmation.title")}
        </StepTitle>
        <SubTitle variant={"body3"}>
          {t("proposal.confirmation.description")}
        </SubTitle>
      </LeftContainer>
      <RightContainer>
        {candidate.proposalLetterInfo?.aboutCompany?.[0] && (
          <ContentArea>
            <Item icon="IconPresentation" title="Sobre a empresa" />
            {renderPreviewComponent("aboutCompany")}
          </ContentArea>
        )}

        <ContentArea>
          <>
            <Item icon="IconIdBadge2" title={t("proposal.confirmation.role")}>
              <ItemSimpleText>
                {candidate.proposalLetterInfo?.role?.name || "Não consta"}
              </ItemSimpleText>
            </Item>

            <Item
              icon="IconBriefcase"
              title={t("proposal.confirmation.department")}
            >
              <ItemSimpleText>
                {candidate.proposalLetterInfo?.department?.name ?? "Não consta"}
              </ItemSimpleText>
            </Item>
          </>

          {candidate.proposalLetterInfo?.assignments && (
            <Item
              icon="IconFileDescription"
              title={"Atribuições e responsabilidades"}
            >
              <HtmlItemSimpleText
                dangerouslySetInnerHTML={{
                  __html: candidate.proposalLetterInfo?.assignments,
                }}
              />
            </Item>
          )}

          <Item icon="IconLink" title={t("proposal.confirmation.hiringType")}>
            <ItemSimpleText>
              {subcategory === "internship"
                ? "Estágio"
                : subcategory?.toUpperCase() ?? "Não consta"}
            </ItemSimpleText>
          </Item>
          <Item
            icon="IconCoin"
            title={
              subcategory === "pj"
                ? "Valor a receber"
                : subcategory === "internship"
                ? "Valor da bolsa auxílio"
                : "Remuneração"
            }
          >
            <ItemSimpleText>
              R${" "}
              {candidate.proposalLetterInfo?.salary
                ? _formatCurrency(candidate.proposalLetterInfo?.salary)
                : "Não consta"}
            </ItemSimpleText>
          </Item>

          {candidate.proposalLetterInfo?.variableSalary ? (
            <Item icon="IconCoin" title={"Remuneração variável"}>
              <HtmlItemSimpleText
                dangerouslySetInnerHTML={{
                  __html: candidate.proposalLetterInfo?.variableSalary,
                }}
              />
            </Item>
          ) : (
            <></>
          )}

          {subcategory === "internship" ? renderInternshipComponent() : <></>}
          <Item
            icon="IconCalendar"
            title={
              subcategory !== "pj"
                ? t("proposal.confirmation.hiringDate")
                : " Data prevista de início"
            }
          >
            <ItemSimpleText>{startDate || "Não consta"}</ItemSimpleText>
          </Item>
          {false && (
            <>
              <Item icon="IconGift" title="Benefícios" />
              <List>
                <li>Plano de saúde Sulamérica Executivo R2</li>
                <li>Plano Odontológico Metlife</li>
                <li>Seguro de Vida Metlife</li>
              </List>
            </>
          )}
        </ContentArea>

        {candidate.proposalLetterInfo?.benefits?.[0] && (
          <ContentArea>
            <Item icon="IconPresentation" title="Benefícios" />
            {renderPreviewComponent("benefits")}
          </ContentArea>
        )}

        {hasRefuse ? (
          <div>
            <Title>
              Poxa, que pena. Tem certeza que deseja recusar a proposta?
            </Title>
            <StyledTextArea
              value={refuseReason}
              onChange={(e) => setRefuseReason(e.target.value)}
              maxLength={100}
              placeholder="Se você se sentir confortável, nos conte o motivo da sua decisão. Isso contribui para a gente melhorar. (Opcional)"
            ></StyledTextArea>
            <StyledSubtitleContainer>
              <StyledSubtitle>Máximo 100 caracteres</StyledSubtitle>
              <StyledSubtitle>{refuseReason.length}/100</StyledSubtitle>
            </StyledSubtitleContainer>
          </div>
        ) : (
          <></>
        )}
        <ButtonArea>
          <Button
            variant="secondary"
            size="large"
            onClick={handleDeny}
            disabled={isRefusingProposal}
            style={{ alignSelf: "center" }}
          >
            {isRefusingProposal ? (
              <Loader size="small" variant="primary" />
            ) : (
              <TextDeny variant={"body3"}>
                {t("proposal.confirmation.buttonDeny")}
              </TextDeny>
            )}
          </Button>
          <Button
            variant="primary"
            size="large"
            onClick={handleAccept}
            disabled={isAcceptingProposal}
            style={{ alignSelf: "center" }}
          >
            {isAcceptingProposal ? (
              <Loader size="small" variant="secondary" />
            ) : (
              <TextConfirm variant={"body3"}>
                {t("proposal.confirmation.buttonConfirm")}
              </TextConfirm>
            )}
          </Button>
        </ButtonArea>
      </RightContainer>
    </Container>
  )
}

export default Mobile
