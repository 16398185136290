import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  padding: ${({ theme }) => theme.spacings.m};
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.feedback.error[40]}`};
`
