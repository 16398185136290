import {
  Button,
  Divider,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  Container,
  IconContainer,
  MainContainer,
  ModalContainer,
} from "./styles"
import { Box, Navbar } from "@Components"
import { useTranslation } from "react-i18next"

interface StartProps {
  handleNextStep: () => void
}

export const Start = ({ handleNextStep }: StartProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationLetter.startStep",
  })

  return (
    <MainContainer>
      <Navbar />

      <Container>
        <ModalContainer>
          <IconContainer>
            <Icons name="IconReportOff" fill="transparent" />
          </IconContainer>

          <Box
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            $width={"100%"}
            gap="xs"
          >
            <Typography variant="headline7" color="secondary.50">
              {t("title")}
            </Typography>
            <Typography variant="body3" color="neutral.50">
              {t("description")}
            </Typography>
            <Divider orientation="horizontal" />
            <div>
              <Button variant="primary" size="large" onClick={handleNextStep}>
                {t("buttonNext")}
              </Button>
            </div>
          </Box>
        </ModalContainer>
      </Container>
    </MainContainer>
  )
}
