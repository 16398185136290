import { Drawer, GenericModal } from "@Components"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { ConfirmationDocumentsWithValue } from "server/src/types"
import styled from "styled-components"

export type ModalPreviewInstructionProps = {
  isOpen: boolean
  handleClose: () => void
  document: ConfirmationDocumentsWithValue
}

const Instructions = styled.div`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 16px;
`

export const ModalPreviewInstruction = ({
  isOpen,
  handleClose,
  document,
}: ModalPreviewInstructionProps) => {
  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      title="Instruções de preenchimento"
      subtitle="Como encontrar ou preencher as informações deste documento"
    >
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
          }}
        >
          <Typography variant="headline8" color="neutral.30">
            {document.title}
          </Typography>

          <Instructions
            style={{
              maxHeight: "70vh",
              overflowY: "scroll",
              overflowX: "hidden",
              wordWrap: "break-word",
            }}
            dangerouslySetInnerHTML={{
              __html: document.fillInstruction?.html || "",
            }}
          />
        </div>
      </>
    </Drawer>
  )
}
