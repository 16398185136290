import { useContext, useEffect } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { trpc } from "src/api/trpc"
import { Context } from "src/context"
import {
  LoadingArea,
  MainContainer,
  PageContents,
  StepContainer,
} from "./styles"
import { Header } from "@Components"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import {
  BasicDataStep,
  CandidateStep,
  DependentsStep,
  StartStep,
  ConfirmationStep,
} from "./screens"
import { Company, HiringCard } from "server/src/types"

const MainPage = () => {
  const { category, cardId, companyId, candidateId, token } =
    useContext(Context)
  const { route } = useParams()
  const navigate = useNavigate()

  const { data, isLoading } = trpc.wizard.getCandidateData.useQuery(
    {
      cardId,
      companyId,
      candidateId,
    },
    {
      enabled: !!category && !!cardId && !!companyId && !!candidateId,
      onSuccess: ({ card }) => {
        const emailLink = card.emailLink.find(
          (emailLink) => emailLink.token && token
        )
        if (!emailLink || emailLink?.expired) {
          navigate("/wizard/invalid")
          return
        }
      },
    }
  )

  useEffect(() => {
    if (route && ["finished"].includes(route)) return

    if (data?.card) {
      const limitDate = data?.card?.limitDates.find((ld) =>
        ld.step.includes("send-documents")
      )

      if (!limitDate) {
        navigate(`../wizard/invalid`, { replace: true })
        return
      }
      if (limitDate && new Date() > new Date(limitDate.date)) {
        navigate(`../wizard/invalid?type=expired`, { replace: true })
        return
      }
    }
  }, [route, data, navigate])

  const renderStep = () => {
    if (!data) return null

    switch (route) {
      case "start":
        return <StartStep candidate={data.candidate} />
      case "basic-data":
        return (
          <BasicDataStep
            candidate={data.candidate}
            card={data.card as HiringCard}
          />
        )
      case "candidate":
        return (
          <CandidateStep
            candidate={data.candidate}
            card={data.card as HiringCard}
          />
        )
      case "dependents":
        return (
          <DependentsStep
            candidate={data.candidate}
            card={data.card as HiringCard}
          />
        )
      case "confirmation":
        return (
          <ConfirmationStep
            candidate={data.candidate}
            card={data.card as HiringCard}
          />
        )
      case "expired":
        return <Navigate replace to={"/wizard/invalid?type=expired"} />
      case "finished":
        return <Navigate replace to={"/wizard/finished?type=documents"} />
      case "download":
        return <Navigate replace to={"/wizard/download"} />
      default:
        return <Navigate replace to={`/wizard/hiring/start`} />
    }
  }

  const hasHeader = !["start"].includes(route || "")

  if (isLoading && !data) {
    return (
      <MainContainer>
        <LoadingArea>
          <Skeleton width={647} height={500} variant="rectangular" />
        </LoadingArea>
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      {hasHeader && data && (
        <Header
          candidate={data.candidate}
          card={data.card as HiringCard}
          company={data.company as Company}
          flowList={data.flowList}
        />
      )}

      <PageContents hasHeader={hasHeader}>
        {hasHeader ? (
          <StepContainer>{renderStep()}</StepContainer>
        ) : (
          renderStep()
        )}
      </PageContents>
    </MainContainer>
  )
}

export default MainPage
