import { SegmentTrackList } from "src/types"
import { segmentTracking } from "@flash-hros/utility"
import { ObjectLiteral } from "@flash-tecnologia/hros-web-ui-v2"

export const createSegmentTrack = ({
  track,
  params,
  identify,
  module = "wizard",
}: {
  track: string
  params?: ObjectLiteral
  identify?: {
    id: string
    params?: ObjectLiteral
  }
  module?: "wizard"
}) => {
  segmentTracking({
    track,
    module,
    businessUnit: "people",
    params,
    identify,
  })
}

export const trackList: SegmentTrackList = {
  ["clt"]: {
    company: {
      pdfViewDownload:
        "company_hiring_clt_singcontract_downloaddocument_clicked",
      pdfViewContinue: "company_hiring_clt_signcontract_continue_clicked",
      pdfViewNextPage: "company_hiring_clt_signcontract_nextpage_clicked",
      analyzeDocument:
        "company_hiring_clt_signcontract_analyzedocument_clicked",
      signContractReturn: "company_hiring_clt_signcontract_return_clicked",
      signContractSign: "company_hiring_clt_signcontract_sign_clicked",
      signContractDownload:
        "company_hiring_clt_signcontract_downloaddocumentsigned_clicked",
    },
    employee: {
      asoUploadFiledClicked:
        "employee_hiring_clt_admissionexam_uploadfile_clicked",
      asoSendDocumentClicked:
        "employee_hiring_clt_admissionexam_senddocument_clicked",
      pdfViewDownload:
        "employee_hiring_clt_singcontract_downloaddocument_clicked",
      pdfViewContinue: "employee_hiring_clt_signcontract_continue_clicked",
      pdfViewNextPage: "employee_hiring_clt_signcontract_nextpage_clicked",
      analyzeDocument:
        "employee_hiring_clt_signcontract_analyzedocument_clicked",
      signContractReturn: "employee_hiring_clt_signcontract_return_clicked",
      signContractSign: "employee_hiring_clt_signcontract_sign_clicked",
      signContractDownload:
        "employee_hiring_clt_signcontract_downloaddocumentsigned_clicked",
      proposalView: "employee_hiring_clt_seeproposal_clicked",
      proposalAccept: "employee_hiring_clt_acceptproposal_clicked",
      proposalRefuse: "employee_hiring_clt_declineproposal_clicked",
      sendDocStart: "employee_hiring_clt_senddocuments_start_clicked",
      sendDocForeign: "employee_hiring_clt_foreigncandidate_selected",
      sendDocBasicDataContinue:
        "employee_hiring_clt_basicdata_continue_clicked",
      sendDocContinue: "employee_hiring_clt_senddocuments_return_clicked",
      sendDocReturn: "employee_hiring_clt_senddocuments_continue_click",
      sendDocDependentToggle: "employee_hiring_clt_adddependent_abled_clicked",
      sendDocDependentAdd: "employee_hiring_clt_addnewdependent_clicked",
      sendDocDependentDelete: "employee_hiring_clt_deletedependent_clicked",
      sendDocDependentContinue:
        "employee_hiring_clt_adddependent_continue_clicked",
      sendDocDependentReturn: "employee_hiring_clt_adddependent_return_clicked",
      sendDocReviewReturn: "employee_hiring_clt_checkdocuments_return_clicked",
      sendDocReviewContinue:
        "employee_hiring_clt_checkdocuments_continue_clicked",
    },
  },
  ["pj"]: {
    company: {
      pdfViewDownload:
        "company_hiring_pj_singcontract_downloaddocument_clicked",
      pdfViewContinue: "company_hiring_pj_signcontract_continue_clicked",
      pdfViewNextPage: "company_hiring_pj_signcontract_nextpage_clicked",
      analyzeDocument: "company_hiring_pj_signcontract_analyzedocument_clicked",
      signContractReturn: "company_hiring_pj_signcontract_return_clicked",
      signContractSign: "company_hiring_pj_signcontract_sign_clicked",
      signContractDownload:
        "company_hiring_pj_signcontract_downloaddocumentsigned_clicked",
    },
    employee: {
      asoUploadFiledClicked:
        "employee_hiring_pj_admissionexam_uploadfile_clicked",
      asoSendDocumentClicked:
        "employee_hiring_pj_admissionexam_senddocument_clicked",
      pdfViewDownload:
        "employee_hiring_pj_singcontract_downloaddocument_clicked",
      pdfViewContinue: "employee_hiring_pj_signcontract_continue_clicked",
      pdfViewNextPage: "employee_hiring_pj_signcontract_nextpage_clicked",
      analyzeDocument:
        "employee_hiring_pj_signcontract_analyzedocument_clicked",
      signContractReturn: "employee_hiring_pj_signcontract_return_clicked",
      signContractSign: "employee_hiring_pj_signcontract_sign_clicked",
      signContractDownload:
        "employee_hiring_pj_signcontract_downloaddocumentsigned_clicked",
      proposalView: "employee_hiring_pj_seeproposal_clicked",
      proposalAccept: "employee_hiring_pj_acceptproposal_clicked",
      proposalRefuse: "employee_hiring_pj_declineproposal_clicked",
      sendDocStart: "employee_hiring_pj_senddocuments_start_clicked",
      sendDocForeign: "employee_hiring_pj_foreigncandidate_selected",
      sendDocBasicDataContinue: "employee_hiring_pj_basicdata_continue_clicked",
      sendDocContinue: "employee_hiring_pj_senddocuments_return_clicked",
      sendDocReturn: "employee_hiring_pj_senddocuments_continue_click",
      sendDocDependentToggle: "employee_hiring_pj_adddependent_abled_clicked",
      sendDocDependentAdd: "employee_hiring_pj_addnewdependent_clicked",
      sendDocDependentDelete: "employee_hiring_pj_deletedependent_clicked",
      sendDocDependentContinue:
        "employee_hiring_pj_adddependent_continue_clicked",
      sendDocDependentReturn: "employee_hiring_pj_adddependent_return_clicked",
      sendDocReviewReturn: "employee_hiring_pj_checkdocuments_return_clicked",
      sendDocReviewContinue:
        "employee_hiring_pj_checkdocuments_continue_clicked",
    },
  },
  ["internship"]: {
    company: {
      pdfViewDownload:
        "company_hiring_internship_singcontract_downloaddocument_clicked",
      pdfViewContinue:
        "company_hiring_internship_signcontract_continue_clicked",
      pdfViewNextPage:
        "company_hiring_internship_signcontract_nextpage_clicked",
      analyzeDocument:
        "company_hiring_internship_signcontract_analyzedocument_clicked",
      signContractReturn:
        "company_hiring_internship_signcontract_return_clicked",
      signContractSign: "company_hiring_internship_signcontract_sign_clicked",
      signContractDownload:
        "company_hiring_internship_signcontract_downloaddocumentsigned_clicked",
    },
    employee: {
      asoUploadFiledClicked:
        "employee_hiring_internship_admissionexam_uploadfile_clicked",
      asoSendDocumentClicked:
        "employee_hiring_internship_admissionexam_senddocument_clicked",
      pdfViewDownload:
        "employee_hiring_internship_singcontract_downloaddocument_clicked",
      pdfViewContinue:
        "employee_hiring_internship_signcontract_continue_clicked",
      pdfViewNextPage:
        "employee_hiring_internship_signcontract_nextpage_clicked",
      analyzeDocument:
        "employee_hiring_internship_signcontract_analyzedocument_clicked",
      signContractReturn:
        "employee_hiring_internship_signcontract_return_clicked",
      signContractSign: "employee_hiring_internship_signcontract_sign_clicked",
      signContractDownload:
        "employee_hiring_internship_signcontract_downloaddocumentsigned_clicked",
      proposalView: "employee_hiring_internship_seeproposal_clicked",
      proposalAccept: "employee_hiring_internship_acceptproposal_clicked",
      proposalRefuse: "employee_hiring_internship_declineproposal_clicked",
      sendDocStart: "employee_hiring_internship_senddocuments_start_clicked",
      sendDocForeign: "employee_hiring_internship_foreigncandidate_selected",
      sendDocBasicDataContinue:
        "employee_hiring_internship_basicdata_continue_clicked",
      sendDocContinue:
        "employee_hiring_internship_senddocuments_return_clicked",
      sendDocReturn: "employee_hiring_internship_senddocuments_continue_click",
      sendDocDependentToggle:
        "employee_hiring_internship_adddependent_abled_clicked",
      sendDocDependentAdd: "employee_hiring_internship_addnewdependent_clicked",
      sendDocDependentDelete:
        "employee_hiring_internship_deletedependent_clicked",
      sendDocDependentContinue:
        "employee_hiring_internship_adddependent_continue_clicked",
      sendDocDependentReturn:
        "employee_hiring_internship_adddependent_return_clicked",
      sendDocReviewReturn:
        "employee_hiring_internship_checkdocuments_return_clicked",
      sendDocReviewContinue:
        "employee_hiring_internship_checkdocuments_continue_clicked",
    },
    institution: {
      pdfViewDownload:
        "institution_hiring_internship_singcontract_downloaddocument_clicked",
      pdfViewContinue:
        "institution_hiring_internship_signcontract_continue_clicked",
      pdfViewNextPage:
        "institution_hiring_internship_signcontract_nextpage_clicked",
      analyzeDocument:
        "institution_hiring_internship_signcontract_analyzedocument_clicked",
      signContractReturn:
        "institution_hiring_internship_signcontract_return_clicked",
      signContractSign:
        "institution_hiring_internship_signcontract_sign_clicked",
      signContractDownload:
        "institution_hiring_internship_signcontract_downloaddocumentsigned_clicked",
    },
  },
}
