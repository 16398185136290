import { parseJWT } from "@flash-hros/utility"

export const useAuthGuard = (token: string) => {
  const isValidToken = () => {
    const path = window.location.pathname
    const page: string = path.split("/")[2]

    const decodedToken = parseJWT({ token })

    const { companyId, cardId, candidateId, employeeId } = decodedToken
    switch (page) {
      case "hiring":
        return companyId && cardId && candidateId
      case "proposal":
        return companyId && cardId && candidateId
      case "signing":
        return companyId && cardId
      case "aso":
        return cardId && candidateId
      case "resignationAso":
        return cardId && employeeId
      case "finished":
        return cardId
      default:
        return true
    }
  }

  return { isValidToken }
}
