interface dispatchToastParams {
  content?: string
  title?: string
  description?: string
  variant?: string
  type: "error" | "warning" | "success"
}

export default function dispatchToast(detail: dispatchToastParams) {
  dispatchEvent(new CustomEvent("showToast", { detail }))
}
