import styled from "styled-components"

export const MainContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => `${theme.colors.neutral[95]}`};
`

export const Container = styled.div`
  background: ${({ theme }) => `${theme.colors.neutral[95]}`};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    border: none;
  }
`

export const ModalContainer = styled.div`
  background: ${({ theme }) => `${theme.colors.neutral[100]}`};
  padding-top: ${({ theme }) => `${theme.spacings.l}`};
  padding-right: ${({ theme }) => `${theme.spacings.l}`};
  padding-left: ${({ theme }) => `${theme.spacings.l}`};
  padding-bottom: ${({ theme }) => `${theme.spacings.s}`};
  border-radius: ${({ theme }) => `${theme.borders.radius.s}`};
  height: fit-content;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const IconContainer = styled.div`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background: ${({ theme }) => theme.colors.secondary[95]};
  color: ${({ theme }) => theme.colors.secondary[50]};

  position: absolute;
  top: -32px;

  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 32px;
    height: 32px;
  }

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 100px;
    height: 100px;
    top: -50px;

    > svg {
      width: 64px;
      height: 64px;
    }
  }
`
