import React from "react"
import { Outlet, Navigate } from "react-router-dom"
import { useAuthGuard } from "@Hooks"
import { trpc } from "src/api/trpc"
import { Loading } from "@Components"

type AuthGuardProps = {
  children?: React.ReactNode
  token: string
}

const AuthGuard = ({ children, token }: AuthGuardProps) => {
  const { isValidToken } = useAuthGuard(token)

  const {
    data: isTokenValid,
    isLoading: isVerifyTokenLoading,
    error,
  } = trpc.helper.verifyToken.useQuery({ token }, { enabled: !!token })

  if (isVerifyTokenLoading) {
    return <Loading />
  }

  if (!isTokenValid || !isValidToken() || error) {
    return <Navigate to={"/wizard/invalid?type=expired"} />
  }

  return children ? <>{children}</> : <Outlet />
}

export default AuthGuard
