import { Signature, SignType } from "server/src/types"
import { Content } from "./Content"
import { InstitutionContent as InstitutionContent } from "./InstitutionContent"
import { WitnessContent as WitnessContent } from "./WitnessContent"
import { Context } from "src/context"
import { useContext } from "react"
import { signingContext } from "../.."
import { Box } from "@Components"

export type StartSigningContentProps = {
  signature: Signature
}

const StartSigningPage = () => {
  const globalContext = useContext(Context)
  const { signData } = useContext(signingContext)

  const foundSignature = signData.contractSignatures.find(
    (signInfo) =>
      signInfo.type === globalContext.type &&
      signInfo.name === globalContext.name
  )

  if (!foundSignature) return <></>

  return (
    <Box
      $height={"100%"}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {globalContext.type === "institution" ? (
        <InstitutionContent signature={foundSignature} />
      ) : globalContext.type === "witness" ? (
        <WitnessContent signature={foundSignature} />
      ) : (
        <Content signature={foundSignature} />
      )}
    </Box>
  )
}

export default StartSigningPage
