import styled from "styled-components"
import { motion } from "framer-motion"

export const MenuCircle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};
  padding: ${({ theme }) => theme.spacings.xs3};
`

export const ProgressBarContainer = styled.div`
  display: flex;
  height: 4px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background-color: rgba(74, 78, 87, 0.1);
`

export const ProgressBar = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100%;
  border-radius: ${({ theme }) => theme.borders.radius.s};
`

export const StepArea = styled.ul`
  margin-top: ${({ theme }) => theme.spacings.xs3};
  position: relative;
  padding-left: ${({ theme }) => theme.spacings.m};
  list-style: none;
  -webkit-tap-highlight-color: transparent;
  &:before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
  div:last-child {
    &:before {
      height: 0;
    }
  }
`

export const MenuContainer = styled.div<{
  active: boolean
  menuHeight: number
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ menuHeight }) => menuHeight}px;
  right: ${({ active }) => (active ? 0 : "-100%")};
  height: ${({ menuHeight }) => `calc(100vh - ${menuHeight}px)`};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  width: 100%;
  z-index: 998;
  transition: right 0.3s ease-in;
`
